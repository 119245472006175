import React from 'react';
import renderHTML from "react-render-html";
import { ACCESS_POINT } from '../../../config';

const ThemeFive = ({ data, InnerData }) => {
	let text = data.content1;
	let ContentTitle = data.contentTitle1;
	console.log('theme 5');
	if (data.contentTitle2 == 'true') {
		let content = [];
		//console.log(data);
		InnerData.map((ival, i) => {
			if (parseInt(data.id) !== 291) {
				content.push(
					<div className="row form-group">
						<div className="col-sm-1" />
						<div className="col-sm-10">
							<div class="about-content" style={{ boxShadow: '0 5px 10px 0 #0000008f', padding: '12px' }}>
								<div className="row form-group">
									<div className="col-sm-12">
										{ival.title && (<p style={{ fontSize: '14px' }}>
											{renderHTML(ival.title)}
										</p>)}
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-1" />
					</div>)
			} else {
				content.push(
					<div className="row form-group">
						<div className="col-sm-2" />
						<div className="col-sm-8">
							<div class="about-content" style={{ boxShadow: '0 5px 10px 0 #0000008f', padding: '12px' }}>
								<div className="row form-group">
									<div className="col-sm-8">
										{ival.title && (<p style={{ fontSize: '14px' }}>
											{renderHTML(ival.title)}
										</p>)}
									</div>
									<div className="col-sm-4">
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-2" />
					</div>
				)
			}
		});
		return (<React.Fragment>
			<div class="breadcrumb-area" style={{ backgroundColor: "#3c5dac" }}>
				<div class="container" style={{ backgroundColor: "#3c5dac" }}>
					<div class="row" style={{ backgroundColor: "#3c5dac" }}>
						<div class="col-10" style={{ marginTop: '7%' }}>
							<nav aria-label="breadcrumb" className="smallroute">
								<ol class="breadcrumb" style={{ marginLeft: '0%' }}>
									<li class="breadcrumb-item"><a href={`/`}><i class="fa fa-home"></i>&nbsp;Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">{data.contentTitle1}</li>
								</ol>
							</nav>
						</div>
						<br />
						<br />
						<div class="about-us-area section-padding-0-100" style={{ width: '100%', marginTop: '2%' }}>
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="about-content" style={{ marginTop: "17px", padding: '55px', paddingTop: '50px', boxShadow: '0 10px 30px 0 #000000' }}>
											<div class="section-heading text-center">
												<h2 class="post-title" style={{ color: "#191919" }}>
													{data.contentTitle1}
												</h2>
											</div>
											{content}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>)
	} else {
		return (
			<React.Fragment>
				<div class="breadcrumb-area">
					<div class="container" >
						<div class="row" >
							<div class="col-12" style={{ marginTop: '7%' }}>
								<nav aria-label="breadcrumb">
									<ol class="breadcrumb" style={{ marginLeft: '0%' }}>
										<li class="breadcrumb-item"><a href="/"><i class="fa fa-home"></i>&nbsp; Home</a></li>
										<li class="breadcrumb-item active" aria-current="page">{ContentTitle}</li>
									</ol>
								</nav>
							</div>
							<br />
							<br />
							<div class="about-us-area section-padding-0-100" style={{ marginTop: '2%' }}>
								<div class="container">
									<div class="row">
										<div class="col-12">
											<div class="about-content" style={{ marginTop: "17px", padding: '55px', paddingTop: '50px', boxShadow: '0 10px 30px 0 #000000' }}>
												<div class="section-heading text-center">
													<h2 class="post-title" style={{ color: "#191919" }}>
														{ContentTitle}
													</h2>
												</div>
												<div class="post-share">
													<p>
														{text && (
															renderHTML(text)
														)}
													</p>
													<br /><br />
												</div>
												{InnerData.map((ival, i) => {
													return (
														<div>
															<div class="post-share">
																<p>{ival.title && (
																	renderHTML(ival.title)
																)}
																</p>
															</div>
															<br /><br />
															<div class="row">
																<div class="col-1"></div>
																<div class="col-4-1">
																	{ival.thumbnail && (
																		<img
																			className="box-shadow-img"
																			src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.thumbnail}`}
																			alt="something went wrong"
																		/>
																	)}
																</div>
																<div class="col-6">
																	{ival.files.map((jval, j) => {
																		return (
																			<div>
																				<a href={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.link}`}><p>
																					<img
																						className="box-shadow-img"
																						// src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/download_1586853763899.jpg`}
																						src={`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/download_1586853763899.jpg`}
																						style={{ width: '14px', marginBottom: '2px' }}
																					/>
																					{jval.name}</p></a><br />
																			</div>
																		)
																	})
																	}
																</div>
															</div>
														</div>)
												})
												}
											</div>
										</div></div>
								</div></div>
						</div>
					</div>
				</div>
			</React.Fragment>)
	}


}

export default ThemeFive;
