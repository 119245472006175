import React from "react";
import renderHTML from "react-render-html";
import { ACCESS_POINT } from "../../../config";

const ThemeOne = ({ data }) => {
  let head = [];
  if (data.id !== "247") {
    head.push(
      <div class="col-10" style={{ marginTop: "7%" }}>
        <nav aria-label="breadcrumb" className="smallroute">
          <ol class="breadcrumb" style={{ marginLeft: "0%" }}>
            <li class="breadcrumb-item">
              <a href={"/"}>
                <i class="fa fa-home"></i>Home
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {data.contentTitle1}
            </li>
          </ol>
        </nav>
      </div>
    );
  } else {
    head.push(<div class="col-10" style={{ marginTop: "7%" }}></div>);
  }
  if (data.id !== "247") {
    return (
      <React.Fragment>
        <div class="breadcrumb-area">
          <div class="container">
            <div class="row">
              {head}
              <div
                class="about-us-area section-padding-0-100"
                style={{ width: "100%", marginTop: "2%" }}
              >
                <div class="container-fluid container-margin-top">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="about-content"
                        style={{
                          marginTop: "71px",
                          paddingTop: "50px",
                          padding: "55px",
                          boxShadow: "0 10px 30px 0 #000000",
                        }}
                      >
                        <div class="section-heading text-center">
                          <h2 class="post-title" style={{ color: "#191919" }}>
                            {data.contentTitle1}
                          </h2>
                        </div>
                        <div class="post-share">
                          {data.content1 && data.id != 251 && (
                            <p>{renderHTML(data.content1)}</p>
                          )}
                          {data.content1 && data.id == 251 && (
                            <p
                              style={{ textAlign: "center", fontSize: "20px" }}
                            >
                              {renderHTML(data.content1)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div class="breadcrumb-area">
          <div class="container">
            <div class="row">
              {head}
              <br />
              <br />
              <div
                class="about-us-area section-padding-0-100"
                style={{ width: "100%", marginTop: "2%" }}
              >
                <div class="container-fluid container-margin-top">
                  <div
                    class="row"
                    style={{ width: "117%", marginLeft: "-94px" }}
                  >
                    <div class="col-9">
                      <div
                        class="about-content"
                        style={{
                          marginTop: "71px",
                          paddingTop: "50px",
                          padding: "55px",
                          boxShadow: "0 10px 30px 0 #000000",
                        }}
                      >
                        <div class="section-heading text-center">
                          <h2 class="post-title" style={{ color: "#191919" }}>
                            {data.contentTitle1}
                          </h2>
                        </div>
                        <div class="post-share">
                          {data.content1 && data.id != 251 && (
                            <p>{renderHTML(data.content1)}</p>
                          )}
                          {data.content1 && data.id == 251 && (
                            <p
                              style={{ textAlign: "center", fontSize: "20px" }}
                            >
                              {renderHTML(data.content1)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div class="col-3">
                      <RightPanel />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default ThemeOne;
