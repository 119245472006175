import React, { Component } from 'react';
import CmsContent from '../../MiddleWare/CmsContent';
import imagethumb from '../../images/CMS/OnlineeducationThinkstockPhotos.jpg';
import renderHTML from 'react-render-html';
import Pagination from 'react-js-pagination';
import NewVideoPlayer from '../../components/Extra/NewVideoPlayer';

class VideoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoList: null,
      videoPlay: null,
      videoId: null,
      videoName: null,
      videoSubtitle: null,
      videoPause: true,
      activePage: null,
      startindex: null,
      endindex: null,
      reload: null,
      arraylength: 0,
      limit: 6,
      adminId: JSON.parse(localStorage.getItem('userDetails')).customerId
    };
  }
  async componentDidMount() {
    try {
      let endindex = this.state.limit;
      this.setState({ activePage: 1, startindex: 0, endindex, reload: true });
      const { data: videoList } = await CmsContent.getAllVideos('mp4', this.state.adminId);
      console.log(videoList);

      if (videoList) {
        this.setState({
          videoList,
          arraylength: videoList.length
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  handlePageChange = pageNumber => {
    this.setState({ reload: null });
    let activePage = pageNumber;
    let startindex;
    let endindex;
    let limit = this.state.limit;
    endindex = activePage * limit;
    startindex = endindex - limit;
    this.setState({ activePage: activePage, startindex: startindex, endindex: endindex, reload: true });
  };

  videoPlay = async (video, videoId, videoName, videoSubtitle, duration = 0, index = 0) => {
    videoName = videoName.split(`/`);
    videoName = videoName[videoName.length - 1];
    video = video + `#t=${duration}`;
    this.setState({
      videoPlay: video,
      videoId,
      videoName,
      videoSubtitle
    });
    let viewArray = {};
    try {
      const viewUpdate = await CmsContent.checkVideoCount(videoId, videoName);

      if (viewUpdate) {

        if (viewUpdate.data[0].count >= 0) {
          viewArray.beforeVideoCount = viewUpdate.data[0].count + 1;
          if (duration === 0) {
            const updateCount = await CmsContent.updateCount(viewUpdate.data[0].id, viewArray);
            if (updateCount) {
              let previousArray = [...this.state.videoList];
              let newArray = { ...previousArray[index] };
              newArray.count = viewUpdate.data[0].count + 1;
              const id = newArray.id;
              const videoList = previousArray.filter(value => value.id !== id);
              videoList.splice(index, 0, newArray);
              this.setState({ videoList });
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onResume = (fulltime, current) => {

    if (current === fulltime) {
      localStorage.setItem('videoId_' + this.state.videoId + '_' + this.state.videoName, 0);
    } else {
      localStorage.setItem('videoId_' + this.state.videoId + '_' + this.state.videoName, current);
    }
  };

  pauseVideo = () => {
    let modal = document.getElementById('myModal');
    modal.style.display = 'block';
    this.setState({ videoPause: false, videoId: '' });
    modal.style.display = 'none';
  };

  render() {
    return (
      <React.Fragment>
        <div className="container " style={{ overflow: 'hidden', padding: '10px' }}>
          <div className="row">
            {this.state.reload &&
              this.state.videoList &&
              this.state.videoList.map((video, index) => {
                if (index >= this.state.startindex && index < this.state.endindex) {

                  let videoName = video.fileName.split(`/`);
                  videoName = videoName[videoName.length - 1];
                  let videoDuration = localStorage.getItem('videoId_' + video.id + '_' + videoName);
                  return (
                    <div className="col-sm-4 my-3">
                      <div className="card h-100">
                        {video.thumbnail && <img className="card-img-top" src={video.thumbnail} alt="Loading" style={{ width: '100%', height: '250px' }} />}
                        {!video.thumbnail && <img className="card-img-top" src={imagethumb} alt="Loading" style={{ width: '100%', height: '250px' }} />}
                        <div className="card-body">
                          <h4 className="card-title">{video.contentTitle1}</h4>
                          <p className="card-text contentDiv mb-3">{renderHTML(video.content1)}</p>
                          <button
                            className="btn btn-success"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => this.videoPlay(video.file, video.id, video.fileName, video.subTitleId, 0, index)}
                          >
                            <span className="mr-1">
                              <i className="fa fa-play-circle" aria-hidden="true" />
                            </span>
                            Watch Video
                          </button>
                          {videoDuration && videoDuration > 0 && (
                            <button
                              className="btn btn-danger ml-2"
                              data-toggle="modal"
                              data-target="#myModal"
                              onClick={() => this.videoPlay(video.file, video.id, video.fileName, video.subTitleId, videoDuration, index)}
                            >
                              <span className="mr-1">
                                <i className="fa fa-play-circle" aria-hidden="true" />
                              </span>
                              Resume Video
                            </button>
                          )}
                          <br />
                          <br />
                          <span className="view-section">
                            <i class="fa fa-eye icon-left" aria-hidden="true">
                              <span className="video-count-spacing">{`${video.count} views`}</span>
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
          <div className="row">
            <div className="col-sm-4" />
            <div className="col-sm-4">
              <Pagination
                prevPageText={<i class="fa fa-caret-left" aria-hidden="true" />}
                nextPageText={<i class="fa fa-caret-right" aria-hidden="true" />}
                activePage={this.state.activePage}
                itemsCountPerPage={1}
                totalItemsCount={this.state.arraylength / this.state.limit}
                pageRangeDisplayed={4}
                onChange={this.handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
              <div className="col-sm-4" />
            </div>
          </div>
        </div>
        <div class="modal fade" id="myModal" data-keyboard="false" data-backdrop="static">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.pauseVideo}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">{this.state.videoId && <NewVideoPlayer Video={this.state.videoId} playfrom={this.state.videoPlay} resume={this.onResume} />}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default VideoList;
