import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import SingleSelect from "../components/Form/SingleSelect";
import CmsContent from '../MiddleWare/CmsContent';
// import { ACCESS_POINT } from '../config/index';
// import logo1 from './Coromandel/logo/VidhyaOnline Multi Lingual 1.png'
import logo1 from './Coromandel/logo/E-Learning.jpg'


// import styles from 'bootstrap-css-only/css/bootstrap.min.css';
class Modal extends Component {
    state = {
        modal14: false,
        modal15: false,
        mobileNumber: '',
        errormobileNumber: '',
        placehold: '',
        placehold1: '',
        loginstate: '',
        loginstate1: '',
        maxLength: 0,
        pageId: 1,
        lang: null,
        username: null,
        date: new Date()
    }

    async componentDidMount() {
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        try {

            const Languagelist = await CmsContent.getFreedom(
                "id as value, language as label",
                "cmndl_tbl_languages",
                "id"
            )
            if (Languagelist && Languagelist.data.length) {
                this.setState({
                    Langlist: Languagelist.data,
                    date
                })
            }
            console.log(this.props);
            if (this.props.modal14) {
                var check = this.props.loginstate;
                var len = 0;
                if (check == 1) {
                    len = 10;
                } else {
                    len = 50;
                }

                this.setState({
                    modal14: this.props.modal14,
                    customerid: this.props.customerid,
                    maxLength: len
                });
                if (check == 1) {
                    this.setState({ loginstate: 'Employee ID', loginstate1: 'Password', placehold: 'Enter Employee Id', placehold1: 'Enter Password' })
                } else if (check == 2) {
                    this.setState({ loginstate: 'Email_ID', placehold: 'Enter Email_ID' })
                }
            }
        } catch (error) {
            console.log(error);
        }

    }

    handlechange = d => {
        this.setState({ [d.target.name]: d.target.value });
    }

    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber],
            mobileNumber: '',
            errormobileNumber: ''
        });
        this.props.these('modal' + nr, false);
    }

    _handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.Submit('Employee');
        }
    };
    get handleKeyDown() {
        return this._handleKeyDown;
    }
    set handleKeyDown(value) {
        this._handleKeyDown = value;
    }

    loginState = async (data) => {

        if (data == 'Mobile') {
            this.setState({ placehold: 'Enter Mobile Number', loginstate: data })
        } else if (data == 'Email_ID') {
            this.setState({ placehold: 'Enter Email_ID', loginstate: data })
        }
    }

    Submit = async (type = null) => {

        let mobileNumber = this.state.mobileNumber;
        const { Employeeid, Password, lang } = this.state;
        if (type == 'Employee') {
            if (this.state.loginstate == 'Employee ID') {

                if (!this.state.Employeeid) {
                    this.setState({ errorEmployeeid: 'Enter Employee Id' });
                    return false;
                } else {
                    this.setState({
                        errorEmployeeid: ''
                    });
                }
            } else if (this.state.loginstate1 == 'Password') {

                if (!this.state.Password) {
                    this.setState({ errorPassword: 'Please Enter Password' })
                    return false;
                } else {
                    this.setState({
                        errorPassword: ''
                    });
                }

            }
            try {

                const Employee = await CmsContent.getFreedom("*", "cmndl_tbl_Employee", `employee_id= '${Employeeid}' and customerid=807 and status = 0`, 1, 1);
                // console.log(Employee, "Employee");
                if (Employee && Employee.data.length) {
                    this.setState({
                        employee_id: Employee.data[0].id,
                        Employee_data: Employee.data[0],
                        EmpPass: Employee.data[0].password,
                        // Mobile: Employee.data[0].Mobile,
                        Email: Employee.data[0].email,
                        username: Employee.data[0].employee_name,
                        errorUser: false
                        // Langlist: Languagelist.data,
                        // date
                    })

                    if (Employee && Employee.data.length) {
                        if (Employee.data[0].is_register == 1 && Employee.data[0].userid) {
                            const getuserdet = await CmsContent.getFreedom(
                                "*",
                                "tbl_user_web",
                                `id=${Employee.data[0].userid} and status = "active"`,
                                1, 1
                            )
                            console.log(getuserdet.data.length, "getuserdet");
                            if (getuserdet && getuserdet.data && getuserdet.data.length) {
                                if (Password == getuserdet.data[0].withoutmd5) {
                                    getuserdet.data[0].Employeeid = Employeeid
                                    localStorage.setItem("userDetails", JSON.stringify(getuserdet.data[0]));
                                    window.location.href = '/'
                                } else {
                                    this.setState({ errorUser: true, errorMsg: 'Incorrect password' })
                                }
                            } else {
                                this.setState({ errorUser: true, errorMsg: 'User Not Found' })
                            }

                        } else {
                            // this.setState({ modal15: true })
                            this.register()
                        }
                    }
                } else {
                    // window.location.href = '/'
                    this.setState({ errorUser: true, errorMsg: 'User Not Found' })
                }
            } catch (error) { console.log(error) }
        } else {
            let datas = {}
            datas.userName = this.state.username;

            datas.language = 1;

            datas.serviceId = 8
            datas.customerId = 807
            datas.userType = 147
            datas.withoutmd5 = this.state.EmpPass
            datas.createdAt = this.state.date
            // datas.mobileNumber = this.state.Mobile
            datas.email = this.state.Email
            datas.status = 'active'
            let datas1 = {}
            datas1.is_register = 1



            try {
                const result = await CmsContent.addMaster(
                    "tbl_user_web", datas
                );
                // console.log(result);
                let getid = result.data.insertId
                datas1.userid = getid
                if (result) {
                    const getuser = await CmsContent.getFreedom(
                        "*",
                        "tbl_user_web",
                        `id=${getid} and customerId=807`,
                        1, 1
                    )

                    try {
                        const result1 = await CmsContent.updateMaster(
                            "cmndl_tbl_Employee", this.state.employee_id, datas1
                        );

                        if (result1 && getuser) {
                            console.log("result1");
                            getuser.data[0].Employeeid = Employeeid
                            localStorage.setItem("userDetails", JSON.stringify(getuser.data[0]));
                            console.log(localStorage.getItem("userDetails"));
                            window.location.href = '/'
                        }
                    } catch (error) {
                        console.log(error);
                    }

                }
            } catch (error) {
                console.log(error)
            }
        }

    }

    resetButton = () => {
        if (this.state.oldState === 'Mobile' || this.state.loginstate === 'Mobile') {
            this.setState({
                errorMobileNumber: '',
                mobileNumber: '',
                placehold: 'Enter Mobile Number',
                loginstate: 'Mobile',
                maxLength: 10
            });
        } else if (this.state.oldState === 'Email_ID' || this.state.loginstate === 'Email_ID') {
            this.setState({
                errorMobileNumber: '',
                mobileNumber: '',
                placehold: 'Enter Email_ID',
                loginstate: 'Email_ID',
                maxLength: 50
            });
        }

    }
    handleSelect = (name, selectedOption) => {
        console.log(name, selectedOption);
        this.setState({
            [name]: selectedOption,
            frsttymlog: true
            //   themeId: selectedOption.value,
            //   themeName: selectedOption.label,
        });
    }
    register() {
        this.setState({
            modal14: false,
            modal15: true
        })
    }

    render() {
        let { pageId, Langlist } = this.state;
        return (
            <MDBContainer >
                {/* <MDBBtn color="primary" onClick={this.toggle(14)}>MDBModal</MDBBtn>*/}
                <MDBModal isOpen={this.state.modal14} toggle={this.toggle(14)} centered>
                    <MDBModalHeader closeAriaLabel='open' toggle={this.toggle(14)} style={{ color: 'rgb(60, 93, 172)' }}>
                        <img src={logo1} alt='' style={{ width: 100, height: 40, resizeMode: 'contain' }} />

                    </MDBModalHeader>
                    <MDBModalBody >
                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-4">
                                <label htmlFor="exampleInputEmail1"
                                    style={{ fontSize: 'initial', marginTop: '5px' }}>
                                    Enter {this.state.loginstate}</label>
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pageName"
                                    name="Employeeid"
                                    placeholder={this.state.placehold}
                                    value={this.state.Employeeid}
                                    onChange={this.handlechange}
                                    onKeyDown={this._handleKeyDown}
                                    maxlength={this.state.maxLength}
                                    style={{ textAlign: 'center' }}
                                />
                            </div>
                            <div className="col-sm-1" />
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-3" />
                            <div className="col-sm-5" >
                                <span className="error-show " style={{ color: 'red' }}>
                                    {this.state.errorEmployeeid}</span>
                            </div>
                            <div className="col-sm-2" />
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-4">
                                <label htmlFor="exampleInputEmail1"
                                    style={{ fontSize: 'initial', marginTop: '5px' }}>
                                    Enter {this.state.loginstate1}</label>
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="pageName"
                                    name="Password"
                                    placeholder={this.state.placehold1}
                                    value={this.state.Password}
                                    onChange={this.handlechange}
                                    onKeyDown={this._handleKeyDown}
                                    // maxlength={this.state.maxLength}
                                    style={{ textAlign: 'center' }}
                                />
                            </div>
                            <div className="col-sm-1" />
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-3" />
                            <div className="col-sm-5" >
                                <span className="error-show " style={{ color: 'red' }}>
                                    {this.state.errorPassword}</span>
                            </div>
                            <div className="col-sm-2" />
                        </div>
                        {this.state.errorUser ? <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-3" />
                            <div className="col-sm-5" >
                                <span className="error-show " style={{ color: 'red' }}>
                                    {this.state.errorMsg}</span>
                            </div>
                            <div className="col-sm-2" />
                        </div> : null}
                    </MDBModalBody>
                    <MDBModalFooter >
                        <MDBBtn color="secondary" onClick={() => this.resetButton()}>Reset</MDBBtn>
                        {this.state.loginstate === 'OTP' && (<MDBBtn color="primary" onClick={() => this.resendOtp()}>Resend OTP</MDBBtn>)}
                        <MDBBtn color="warning" onClick={() => this.Submit('Employee')}>Login</MDBBtn>
                        <br />
                        {/* <MDBBtn color="warning" onClick={()=>this.register()}>Register</MDBBtn>  */}
                    </MDBModalFooter>
                </MDBModal>
                <MDBModal isOpen={this.state.modal15} toggle={this.toggle(15)} centered>
                    <MDBModalHeader closeAriaLabel='open' toggle={this.toggle(15)} style={{ color: 'rgb(60, 93, 172)' }}>
                        <img src={logo1} alt='' style={{ width: 100, height: 40, resizeMode: 'contain' }} />

                    </MDBModalHeader>
                    <MDBModalBody >
                        {/* <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">Prefered language</label>
                            </div>
                            <div className="col-sm-5">
                                <SingleSelect
                                    options={Langlist}
                                    handleChange={(e) => this.handleSelect('lang', e)}
                                    selectedService={this.state.lang}
                                />
                            </div>
                            <div className="col-sm-3" />
                        </div> */}
                        {/* <div className="row form-group">
                            <div className="col-sm-3" />
                            <div className="col-sm-4">

                                < p style={{ color: "red" }}>{this.state.errorlang}</p>


                            </div>
                        </div> */}

                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">Name</label>
                            </div>
                            <div className="col-sm-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pageName"
                                    name="Code"
                                    placeholder="Enter name"
                                    value={this.state.username}
                                    onChange={(e) => { this.handleSelect("username", e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-3" />
                            <div className="col-sm-4">

                                < p style={{ color: "red" }}> {this.state.errorName}</p>
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="warning" onClick={() => this.Submit('new')}>Login</MDBBtn>
                        <br />
                        {/* <MDBBtn color="warning" onClick={()=>this.register()}>Register</MDBBtn>  */}
                    </MDBModalFooter>
                </MDBModal>

            </MDBContainer>
        );
    }
}

export default Modal;