import React, { Component } from "react";
import './courses.css'
import CmsContent from "../../MiddleWare/CmsContent";
// import DownPanel from "./DownPanel";
import RightPanel from "./RightPanel";
import { Link } from "react-router-dom";
import { ACCESS_POINT } from "../../config";
import renderHTML from "react-render-html";
import Pagination from "react-js-pagination";
import { data } from "browserslist";
import LoginModal from '../LoginModal'
import moment from "moment";
import Loader from "../../components/Loader";
// import {thumbNail} from "../."
let ima = ['https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvEhCM1QFpMWMX5Gn22s7x21acHgFf45kBXKM2UZCkzaqK8dt3qN6Ya8-RmkMwlb366jw&usqp=CAU']
class ListingMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: [],
            user: localStorage.getItem("userDetails"),
            arraylength: 0,
            limit: 9,
            activePage: null,
            startindex: null,
            endindex: null,
            reload: null,
            overlays: [],
            disabled1: true,
            quizArray: [],
            showSequence: false,
            modal14: false
        };
    }
    async componentDidMount() {
        // const {
        //   match: { params },
        // } = this.props;
        // let id = atob(params["id"]);
        try {
            // console.log(id);
            let endindex = this.state.limit;
            this.setState({ activePage: 1, startindex: 0, endindex, reload: true });
            if (this.state.user == null) {
                this.setState({ modal14: true })
                return true
            }
            let result = await CmsContent.getCoursesList(JSON.parse(this.state.user), 310);
            console.log(result.data, 'dat..');
            var dat = []
            if (result && result.data) {
                dat = result.data
            }

            if (dat.length) {
                //    let  sequenceCount = Object.keys(this.groupBy(dat, 'clusterId'));
                // let sequenceCount = this.groupByArray(dat, 'clusterId');
                this.setState({ result: dat, arraylength: dat.length });
            }

            this.setState({ disabled1: false })
        } catch (error) {
            this.setState({ disabled1: false })
            console.error(error);
        }
    }
    groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    }
    groupByArray = (array, key) => {
        return Array.from(array
            .reduce((m, o) => m.set(o[key], [...(m.get(o[key]) || []), o]), new Map)
            .values()
        );
    }
    handlePageChange = pageNumber => {
        this.setState({ reload: null });
        let activePage = pageNumber;
        let startindex;
        let endindex;
        let limit = this.state.limit;
        endindex = activePage * limit;
        startindex = endindex - limit;
        this.setState({ activePage: activePage, startindex: startindex, endindex: endindex, reload: true });
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    call = id => {
        console.log(this.props.props);
        if (this.state.user && this.state.user.length > 0) {
            if (id.themeName == "VideoContent" || id.themeName == 3) {
                window.location.href = `/vidyaonline/VideoContent/${id.pageId}`;
            } else {
                window.location.href = `/vidhya/${id.categoryName}/${id.pageId}`;
            }
        } else {
            this.props.requireLogin(id)
        }
    }
    MobileSign = async (s, v) => {
        this.setState({ [s]: v });
        const { user, rot, theme } = this.state;
        let pageId = 578;

        if (user == null) {
            window.location.href = `/`;
        } else if (user.length) {
            if (this.state.routepage) {

                this.call(this.state.routepage)
            } else {
                window.location.href = `/`

            }
            this.setState({ logButton: "Logout" });

        }
    };
    percentage = (partialValue, totalValue) => {
        let int = (100 * partialValue) / totalValue
        // return `In progress (${Math.floor(int)} % / 100%)`
        return `${Math.floor(int)}%`
        // return `In progress (${Math.ceil(int)} % / 100%)`
    }
    ViewCertificate = d => {
        let username = JSON.parse(this.state.user).userName
        window.open(`${window.location.origin}/vidya/Certificate/${btoa(d.quizlabel)}/${btoa(username)}/${btoa(d.attendedOn)}/${btoa(d.total * 100 / d.totalQue)}/${btoa(d.posttotal * 100 / d.totalQue)}`);
    }
    ViewCertificate1 = d => {
        let username = JSON.parse(this.state.user).userName
        console.log(d);
        window.open(`${window.location.origin}/vidya/Certificate/${btoa(d.data[0].subCategoryName)}/${btoa(username)}/${btoa(d.lastSeen)}/${btoa(d.total * 100 / d.totalQue)}/${btoa(d.posttotal * 100 / d.totalQue)}`);
    }
    buttonS = (ival, id) => {

        let buttonname = 'Start'
        let isCertif = false
        if (ival.total && (ival.totaltime != ival.maxtime)) {
            buttonname = 'Resume'
        } else if (ival.total && (ival.totaltime == ival.maxtime)) {
            buttonname = 'Completed'
        } else if (ival.lastSeen) {
            buttonname = 'Resume'
            let val = ival.maxtime * 100 / ival.totaltime;
            if (val >= 90) {
                buttonname = 'Completed'
                isCertif = true
            }

        } else {
            buttonname = 'Start'
        }
        let isQuizthere = true
        if (ival.data[0].videoOverlay) {
            if (JSON.parse(ival.data[0].videoOverlay).length == 0) {
                isQuizthere = false
            }
            // console.log(JSON.parse(ival.data[0].videoOverlay), isQuizthere, " ival.videoOverlay ");
        }
        return (
            <div>
                <button onClick={() => this.call(id, ival)} style={{ float: 'right', width: '100%' }} className="btn btn-success waves-effect">{buttonname}</button>
                <br /> <br />
                {(this.certified(ival)) ?
                    <button onClick={() => this.ViewCertificate(ival)} style={{ float: 'right', width: '100%' }} className="btn btn-danger waves-effect">View Certificate</button>
                    :
                    isQuizthere ? <span class='text-danger' >Quiz Incomplete</span> :
                        isCertif && <button onClick={() => this.ViewCertificate1(ival)} style={{ float: 'right', width: '100%' }} className="btn btn-danger waves-effect">View Certificate</button>

                }
            </div>
        )
    }
    certified = (ival, id) => {
        if (ival.total) {
            let val = ival.total * 100 / ival.totalQue;
            if (val >= 100) {
                return true
            } else {
                return false
            }
        }
        else return false
    }
    sequeceSection = (val) => {
        var m1 = [];
        var c1 = [];
        var intermediate = false;
        val.map((ival, i) => {
            if (ival && ival.total && i == 0) {
                c1[i] = true
                c1[i + 1] = true;
            } else if (ival && ival.total && i != 0 && c1[i - 1]) {
                c1[i] = true;
                c1[i + 1] = true;
            } else if (ival.total == 0 && ival.quizlabel == null && c1[i]) {
                c1[i + 1] = true
            } else if (c1[i]) {
                c1[i + 1] = false
            }
        });
        return (
            <React.Fragment>
                <button onClick={() => this.setState({ showSequence: false })} className="btn btn-danger waves-effect">Back</button>
                <div class="container">

                    {val.map((ival, i) => {



                        return (
                            <div className="card card-shadow shadow1">
                                <div className="body">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <span className="ImagePause" style={{ float: 'right' }} />

                                            {this.certified(ival, i) ?
                                                <img src={'/static/media/E-Learning.352b4321.jpg'} alt='' style={{ width: 50, height: 50, position: 'absolute', }} />
                                                : null}

                                            <img className="img-responsive thumbnail" style={{ width: '100%', height: 'auto', objectFit: 'contain', maxHeight: 130 }}
                                                src={ival.data[0].thumbnail ? `${ACCESS_POINT}/cmsContent/getImages/` + ival.data[0].thumbnail : "/static/media/E-Learning.352b4321.jpg"}
                                            // width="130px;"
                                            />
                                        </div>
                                        <div className="col-lg-5">
                                            <p className='center' style={{ color: '', fontSize: '17px', textAlign: 'center', fontWeight: 'bold' }} onClick={() => i == 0 ? this.call(ival.data[0]) : c1[i] ? this.call(ival.data[0]) : null}>
                                                {ival.data[0].subCategoryName}
                                            </p>
                                            {ival.attendedOn && <p className='' style={{ color: '', fontSize: '12px', textAlign: 'center', marginBottom: 2 }} onClick={() => i == 0 ? this.call(ival.data[0]) : c1[i] ? this.call(ival.data[0]) : null}>
                                                Attended: {moment(ival.attendedOn).format('DD-MMM-YYYY HH:MM:SS')}
                                            </p>}
                                            {ival.lastSeen && <p className='' style={{ color: '', fontSize: '12px', textAlign: 'center', marginBottom: 2 }} onClick={() => i == 0 ? this.call(ival.data[0]) : c1[i] ? this.call(ival.data[0]) : null}>
                                                Last Seen: {moment(ival.lastSeen).format('DD-MMM-YYYY HH:MM:SS')}
                                            </p>}
                                        </div>
                                        <div className="col-lg-1" />
                                        <div className="col-lg-2 center">

                                            {i == 0 ?
                                                this.buttonS(ival, ival.data[0]) :
                                                c1[i] ?
                                                    this.buttonS(ival, ival.data[0]) :
                                                    ival.data[0].cluster_lock == '0' ?
                                                        this.buttonS(ival, ival.data[0]) :
                                                        <i class="fa fa-lock" style={{ float: 'right' }} aria-hidden="true"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div >
                        )
                    })}
                </div>


            </React.Fragment >
        )
    }
    handleFlip = (vals, ival, i) => {
        let { result, val } = this.state;
        if (vals == 'val') {
            val[i].flip = val[i].flip ? false : true
        } else {

            result[i].flip = result[i].flip ? false : true
        }
        this.setState({ result, val })
    }
    innerContent = (ival, i) => {
        if (i >= this.state.startindex && i < this.state.endindex) {
            return (

                <div className="col-lg-3  shadow2  col-md-6 align-items-space-between" style={{
                    backgroundColor: 'white', padding: 10, borderRadius: 8,
                    margin: 20
                }}>
                    <img className="img-responsive thumbnail"
                        style={{ width: '100%', height: 150, objectFit: 'contain' }}
                        src={(ival.data && ival.data[0] && ival.data[0].File && ival.data[0].File[0] && JSON.parse(ival.data[0].File)[0].split("/")[0] == "false") || (ival.data && ival.data[0] && ival.data[0].File == null) ?
                            "/static/media/E-Learning.352b4321.jpg":`${ACCESS_POINT}/cmsContent/getImages/E-Learning/` + JSON.parse(ival.data[0].File)[0].split("/")[1]
                        }
                    />
                    {/* <img className="img-responsive thumbnail"
                        style={{ width: '100%', height: 150, objectFit: 'contain' }}
                        src={'/static_images/VidhyaOnlinenew_1638857098850.png'}
                    /> */}
                    {/* <img className="img-responsive thumbnail"
                        style={{ width: '100%', height: 150, objectFit: 'contain' }}
                        src={ival.data[0].File ? '/static_images/' + JSON.parse(ival.data[0].File)[0].split('/')[1] : ima[0]}
                    /> */}
                    <div className={`col-lg-12 ${ival.flip ? 'flip' : 'flip1'}`}>
                        {/* <div className="col-lg-6 "> */}
                        {ival.flip ?
                            <div style={{ transform: 'rotateY( 180deg )', paddingTop: 20 }}>
                                <div class="d-flex justify-content-between">
                                    <div class="p-2" style={{ fontSize: 10 }} >{ival.completedCourse > 0 ? <span style={{ color: 'green' }}>Started</span> : <span style={{ color: 'red' }}>Not Started</span>}</div>

                                    <div class="p-2" style={{ fontSize: 10 }} >{ival.totalCourses} Item(s)</div>
                                </div>

                                <div className="col-lg-6">
                                    <div class="progress">
                                        <div class="progress-bar" style={{ width: `${this.percentage(ival.completedCourse, ival.totalCourses)}` }} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.percentage(ival.completedCourse, ival.totalCourses)}</div>
                                    </div>
                                </div>
                                <div className="col-lg-6" style={{ padding: 10, }}>
                                    <button className='btn col-xs-6' onClick={() => this.handleFlip('result', ival, i)} style={{ float: 'left' }} >
                                        <i class="fa fa-angle-left " aria-hidden="true"></i> {" "}
                                        Back</button>
                                </div>
                            </div>
                            :
                            <div >
                                <p className='' onClick={() => this.handleModule(ival.data)} style={{ cursor: 'pointer', minHeight: 100, textOverflow: 'ellipsis', color: '#0b0b0b', fontSize: '15px', fontWeight: 'bold', textAlign: 'center', padding: 10, }}>
                                    {ival.clusterName}
                                </p>
                                {/* </div> */}
                                <div className="col-xs-6" style={{ padding: 10, }}>
                                    <button className='col-xs-6 btn btn-success' onClick={() => this.handleModule(ival.data)} style={{ float: 'right', fontWeight: 'bold' }} >View</button>
                                    <button className='btn col-xs-6' onClick={() => this.handleFlip('result', ival, i)} style={{ float: 'left', fontWeight: 'bold', fontSize: 10 }} >Click for details</button>
                                </div>
                            </div>}
                    </div>
                </div>


            )
            return (
                <div className="card card-shadow">
                    <div className="body" style={{ padding: 20 }} >
                        <div className="row">
                            <div className="col-sm-3">
                                <span className="ImagePause" style={{ float: 'right' }} />
                                <img className="img-responsive thumbnail"
                                    style={{ width: '100%', height: 'auto', maxHeight: 150, objectFit: 'contain' }}
                                    src={ival[0].File ? ACCESS_POINT + '/superAdmin/file?fileurl=' + JSON.parse(ival[0].File)[0] : ima[0]}
                                // width="130px;" 
                                />

                            </div>
                            <div className="col-sm-6">
                                <p style={{ color: '#0b0b0b', fontSize: '24px', textAlign: 'center' }}>
                                    {ival[0].clusterName}
                                </p>
                                {/* <p style={{ fontSize: '15px' }}>
                                    <span>sub Title</span>
                                </p> */}
                            </div>
                            <div className="col-sm-2" />
                            <div className="col-sm-1">
                                <button className='btn btn-success' onClick={() => this.handleModule(ival)} style={{ float: 'right' }} >View</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    handleModule = (ival) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        this.setState({ showSequence: true, val: ival })
    }
    content = (result) => {
        return (
            <React.Fragment>
                <section id="courses" class="courses" style={{ backgroundColor: '#eef4f4' }}>
                    <div class="container" data-aos="fade-up">
                        {this.props.home && < div class="section-title">
                            <h2>Courses</h2>
                            <p>Popular Courses</p>
                        </div>}


                        {/* <div class="row" data-aos="zoom-in" data-aos-delay="100"> */}
                        {(this.state.showSequence) ? (
                            <React.Fragment>

                                {this.sequeceSection(this.state.val)}
                            </React.Fragment>
                        ) : (<React.Fragment>
                            <div class="container">

                                <div className='row  justify-content-center'>
                                    {/* <div className="col-lg-1" /> */}
                                    {result.map((ival, i) => {
                                        return this.innerContent(ival, i)

                                    })}

                                </div>


                            </div>
                            <br />
                            <br />
                            <br />
                            <div className="row">
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <Pagination
                                        prevPageText={<i class="fa fa-caret-left" aria-hidden="true" />}
                                        nextPageText={<i class="fa fa-caret-right" aria-hidden="true" />}
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={1}
                                        totalItemsCount={this.state.arraylength / this.state.limit}
                                        pageRangeDisplayed={4}
                                        onChange={this.handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </div>
                        </React.Fragment>)}
                    </div>
                </section>

            </React.Fragment >
        );
    };
    render() {
        let { result, disabled, disabled1 } = this.state;
        console.log({ result });
        return (
            <React.Fragment>
                <Loader isOpen={disabled1} />
                <main id="main" data-aos="fade-in">
                    {result.length ? this.content(result) :
                        disabled1 ? null : <h3 style={{ textAlign: 'center', alignItems: 'center', margin: 50 }}>Courses Not Found...</h3>}
                </main>
                {this.state.modal14 && (window.location.pathname != "/") && <LoginModal
                    modal14={true}
                    customerid={807}
                    loginstate={1}
                    these={this.MobileSign}
                />}
            </React.Fragment>
        );
    }
}
export default ListingMore;
