import React, { Component } from "react";
import Device from "react-device";
import AdminRoutes from "./Routes/AdminRoutes";
import CmsContent from "./MiddleWare/CmsContent";
import TempRoutes from "./Routes/TempRoutes";
const onChange = deviceInfo => {
  if (deviceInfo.device.vendor === undefined) {
    localStorage.setItem("device", "Desktop");
  } else {
    localStorage.setItem("device", deviceInfo.device.vendor);
  }
  localStorage.setItem("browser", deviceInfo.browser.name);
  localStorage.setItem("os", deviceInfo.os.name);
};
class App extends Component {
  async componentWillMount() {
    let ips = await CmsContent.getIP();
    if (ips) {
      localStorage.setItem("IP", ips.data.ip);
      localStorage.setItem("IPTime", ips.data.time);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Device onChange={onChange} />
        <AdminRoutes />
        {/* <TempRoutes /> */}
      </React.Fragment>
    );
  }
}

export default App;
