import React from 'react'
import logo1 from './logo/VidhyaOnline new.png'
export default function Home_Temp() {
    return (


        // <div class="text-center" data-aos="fade-left" data-aos-delay="100">
        //     <img src={logo1} class="img-fluid" alt="sorry" />
        // </div>
        <React.Fragment>
            <div className='container-fluid'>

                <div class="row d-flex justify-content-center">

                    <div class="col text-center"
                        style={{ marginBottom: "50px" }}
                    >

                        <img src={logo1} class="middleofnoware" alt="sorry" />
                    </div>
                </div>
                <div class="row ">
                    <div class="col text-center"
                       
                        style={{ marginBottom: "30px" }}
                    >
                        <p>E-LEARNING is currently undergoing a scheduled maintenance.</p>
                        <p>Please visit back after sometime.</p>
                        {/* <p>Dear Valued Channel Partner,</p>
                        <p>We appreciate your interest on visiting this Coromandel Knowledge Portal which will provide you </p>
                        <p>the required information on Coromandel Products, Crops, Basics of Agriculture.</p> */}

                    </div>
                </div>
                {/* <div class="row text-center">
                    <div class="col"
                        
                        style={{ marginBottom: "30px" }}
                    >
                        <p style={{ fontSize: 30, fontWeight: 500 }}>Launch date of this portal</p>
                    </div>
                </div> */}
                <div class="row text-center">
                    <div class="col"
                                        
                        style={{ marginBottom: "30px" }}
                    >
                        <h4 style={{ fontSize: 35, fontWeight: 500 }}>Thank you</h4>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )

}
