import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import SingleSelect from "../../components/Form/SingleSelect";
import CmsContent from '../../MiddleWare/CmsContent';
// import { ACCESS_POINT } from '../config/index';
import logo1 from '../Coromandel/logo/VidhyaOnline Multi Lingual 1.png'
import { ACCESS_POINT } from '../../config';
import Home from './Home';
var CryptoJS = require("crypto-js");
function DecryptData(encryptedData) {
    var decryptedText = null;
    try {
        //Creating the Vector Key
        var iv = CryptoJS.enc.Hex.parse('a414322549cd8212378b2f055046217b');
        //Encoding the Password in from UTF8 to byte array
        var Pass = CryptoJS.enc.Utf8.parse('C0r0m@nde!');
        //Encoding the Salt in from UTF8 to byte array
        var Salt = CryptoJS.enc.Utf8.parse("DealerPortal2ViddyaOnline");
        //Creating the key in PBKDF2 format to be used during the decryption
        var key128Bits1000Iterations = CryptoJS.PBKDF2(Pass.toString(CryptoJS.enc.Utf8), Salt, { keySize: 128 / 32, iterations: 1000 });
        //Enclosing the test to be decrypted in a CipherParams object as supported by the CryptoJS libarary
        var cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(encryptedData)
        });

        //Decrypting the string contained in cipherParams using the PBKDF2 key
        var decrypted = CryptoJS.AES.decrypt(cipherParams, key128Bits1000Iterations, { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });
        decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        return decryptedText;
    }
    //Malformed UTF Data due to incorrect password
    catch (err) {
        return null;
    }
}

// import styles from 'bootstrap-css-only/css/bootstrap.min.css';
class Login extends Component {
    state = {
        registerForm: false,
        dealer_data: [],
        modal15: false,
        frsttymlog: false,
        dealer_code: "",
        mobileNumber: '',
        errormobileNumber: '',
        placehold: '',
        loginstate: '',
        maxLength: 0,
        lang: null,
        username: null,
        // Langlist: [{ label: 'TAMIL', value: 1 }, { label: 'TELUGU', value: 2 }, { label: 'MALAYALAM', value: 3 }, { label: 'HINDI', value: 4 }, { label: 'ENGLISH', value: 5 }]
    }

    async componentDidMount() {

        await localStorage.clear()
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

        try {
            // console.log(this.props.match.params.code);
            const Languagelist = await CmsContent.getFreedom(
                "id as value, language as label",
                "cmndl_tbl_languages",
                "id"
            )
            if (Languagelist && Languagelist.data.length) {
                this.setState({
                    Langlist: Languagelist.data,
                    date
                })
            }
            // let code = await DecryptData(this.props.match.params.code)
            let code = this.props.match.params.code
            // console.log(code, 'code', this.props.match.params.code)
            // return true
            if (code) {
                const dealer = await CmsContent.getFreedom("*", "cmndl_tbl_dealer", `dealer_code= '${code}'`, 1, 1);
                console.log(dealer, 'dealer');
                if (dealer && dealer.data.length) {
                    this.setState({
                        dealer_code: code,
                        dealer_id: dealer.data[0].id,
                        dealer_data: dealer.data[0],
                        Mobile: dealer.data[0].Mobile,
                        Email: dealer.data[0].Email,
                        username: dealer.data[0].dealer_name
                        // Langlist: Languagelist.data,
                        // date
                    })

                    if (dealer && dealer.data.length) {
                        if (dealer.data[0].is_register == 1 && dealer.data[0].userid != null) {
                            const getuserdet = await CmsContent.getFreedom(
                                "*",
                                "tbl_user_web",
                                `id=${dealer.data[0].userid}`,
                                1, 1
                            )
                            console.log(getuserdet.data);
                            localStorage.setItem("userDetails", JSON.stringify(getuserdet.data[0]));
                            window.location.href = '/'

                        } else {
                            this.setState({ registerForm: true })
                        }
                    }
                } else {
                    window.location.href = '/'
                    // this.setState({ registerForm: true })
                }
            }

        } catch (error) {
            console.log(error);
        }

    }


    MobileSign = async (s, v) => {
        console.log('login');
        this.setState({ [s]: v });
        if (s == "registerForm") {
            window.location.href = `/`
        } else {
            if (this.state.userlog == null) {
                window.location.href = `/`;
            } else {
                window.location.href = `/`;
            }
        }
    };

    handlechange = d => {
        this.setState({ mobileNumber: d.target.value });
    }

    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber],
            mobileNumber: '',
            errormobileNumber: ''
        });
        this.MobileSign('modal' + nr, false);
    }

    _handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.Submit();
        }
    };

    loginState = async (data) => {

        if (data == 'Mobile') {
            this.setState({ placehold: 'Enter Mobile Number', loginstate: data })
        } else if (data == 'Email_ID') {
            this.setState({ placehold: 'Enter Email_ID', loginstate: data })
        }
    }

    Submit = async () => {
        let { username, lang, frsttymlog, dealer_id, dealer_data } = this.state
        // console.log(this.state.lang.value, this.state.username);
        // console.log("enter submit");
        // console.log(lang, username);
        await this.setState({ errorlang: null, errorName: null })

        console.log("condition");

        if (!lang) {
            console.log("lang not sel");
            this.setState({
                errorlang: 'Select Language'
            })
            return true
        }
        else if (!username) {
            console.log("name not sel");
            this.setState({
                errorName: 'Enter Name'
            })
            return true
        }

        // console.log("okkkk");
        let datas = {}
        datas.userName = username;
        datas.language = lang.value;
        datas.serviceId = 8
        datas.customerId = 807
        // datas.userType = 6
        datas.userType = 148
        datas.withoutmd5 = "welcome123"
        datas.createdAt = this.state.date
        datas.mobileNumber = this.state.Mobile
        datas.email = this.state.Email
        datas.status = 'active'
        let datas1 = {}
        datas1.is_register = 1

        let dealer_code = this.state.dealer_code
        // console.log(datas);
        try {
            const result = await CmsContent.addMaster(
                "tbl_user_web", datas
            );
            // console.log(result);
            let getid = result.data.insertId
            datas1.userid = getid
            if (result) {
                const getuser = await CmsContent.getFreedom(
                    "*",
                    "tbl_user_web",
                    `id=${getid}`,
                    1, 1
                )
                // console.log(getuser);
                const result1 = await CmsContent.updateMaster(
                    "cmndl_tbl_dealer", dealer_id, datas1
                );
                // console.log(result1.data.insertId);
                if (result1) {
                    console.log("result1");
                    localStorage.setItem("userDetails", JSON.stringify(getuser.data[0]));
                    console.log(localStorage.getItem("userDetails"));
                    window.location.href = '/'
                }
            }
        } catch (error) { console.log(error) }



    }

    resetButton = () => {
        if (this.state.oldState === 'Mobile' || this.state.loginstate === 'Mobile') {
            this.setState({
                errorMobileNumber: '',
                mobileNumber: '',
                placehold: 'Enter Mobile Number',
                loginstate: 'Mobile',
                maxLength: 10
            });
        } else if (this.state.oldState === 'Email_ID' || this.state.loginstate === 'Email_ID') {
            this.setState({
                errorMobileNumber: '',
                mobileNumber: '',
                placehold: 'Enter Email_ID',
                loginstate: 'Email_ID',
                maxLength: 50
            });
        }

    }

    resendOtp = async () => {
        let phone = this.state.phone;
        const result = await CmsContent.getOtp(`${phone}`);
        if (result) {
            //console.log(result);
            this.setState({
                placehold: 'Enter OTP',
                mobileNumber: '',
                loginstate: 'OTP',
                phone: phone,
                errorMobileNumber: ''
            });
        }
    }
    handleSelect = (name, selectedOption) => {
        console.log(name, selectedOption);
        this.setState({
            [name]: selectedOption,
            frsttymlog: true
            //   themeId: selectedOption.value,
            //   themeName: selectedOption.label,
        });
    };
    // register(){
    // this.setState({modal14:false,
    // modal15:true})
    // }
    render() {
        const { Langlist } = this.state;
        // console.log(this.state.Langlist);
        return (
            <MDBContainer >
                {/* <MDBBtn color="primary" onClick={this.toggle(14)}>MDBModal</MDBBtn>*/}
                <MDBModal isOpen={this.state.registerForm} toggle={this.toggle(14)} centered>
                    <MDBModalHeader closeAriaLabel='open' toggle={this.toggle(14)} style={{ color: 'rgb(60, 93, 172)' }}>
                        <img src={logo1} alt='' style={{ width: 100, height: 40, resizeMode: 'contain' }} />

                    </MDBModalHeader>
                    <MDBModalBody >
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">Prefered language</label>
                            </div>
                            <div className="col-sm-5">
                                <SingleSelect
                                    options={Langlist}
                                    handleChange={(e) => this.handleSelect('lang', e)}
                                    selectedService={this.state.lang}
                                />
                            </div>
                            <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-3" />
                            <div className="col-sm-4">

                                {this.state.errorlang && this.state.errorlang != null && < p style={{ color: "red" }}> * {this.state.errorlang}</p>
                                }

                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">Name</label>
                            </div>
                            <div className="col-sm-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pageName"
                                    name="Code"
                                    placeholder="Enter name"
                                    value={this.state.username}
                                    onChange={(e) => { this.handleSelect("username", e.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-3" />
                            <div className="col-sm-4">

                                {this.state.errorName && this.state.errorName != null && < p style={{ color: "red" }}> * {this.state.errorName}</p>
                                }
                            </div>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" onClick={() => this.resetButton()}>Reset</MDBBtn>
                        {this.state.loginstate === 'OTP' && (<MDBBtn color="primary" onClick={() => this.resendOtp()}>Resend OTP</MDBBtn>)}
                        <MDBBtn color="warning" onClick={() => this.Submit()}>Login</MDBBtn>
                        <br />
                        {/* <MDBBtn color="warning" onClick={()=>this.register()}>Register</MDBBtn>  */}
                    </MDBModalFooter>
                </MDBModal>

            </MDBContainer >
        );
    }
}

export default Login;