import React, { Component } from "react";
import { Link } from "react-router-dom";

import cmsContent from "../../MiddleWare/CmsContent";
import { ACCESS_POINT } from "../../config";

class RightPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      adminId: 807,
      user: localStorage.getItem("userDetails")
      //  JSON.parse(localStorage.getItem("userDetails")).customerId,
    };
  }
  async componentDidMount() {
    const result = await cmsContent.getRightpanel(this.state.adminId);
    var data = result.data;
    data = data.sort(cmsContent.compareValues("id", "int", "desc"));
    //console.log(data);
    // const result = await cmsContent.getFreedom(
    //   "tbl_pages.Date,tbl_pages.id,tbl_rightPanel_mapping.categoryName,tbl_rightPanel_mapping.content1,tbl_rightPanel_mapping.file,theme_master.themeName",
    //   "tbl_rightPanel_mapping,tbl_pages,theme_master",
    //   `tbl_pages.id = tbl_rightPanel_mapping.pageId and tbl_pages.themeId = theme_master.themeId and tbl_rightPanel_mapping.status ='active' and tbl_pages.customerId=${this.state.adminId} and tbl_rightPanel_mapping.customerId=tbl_pages.customerId`,
    //   "tbl_rightPanel_mapping.id",
    //   "tbl_rightPanel_mapping.id"
    // );

    if (result) {
      this.setState({ dataList: result.data });
    }
  }

  getdate(date) {
    var dataformate = date.split("-");
    return dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0];
  }
  call = id => {

    if (this.state.user && this.state.user.length > 0) {
      if (id.themeId == 3 || (id.themeId == "3") || id.themeName == "VideoContent") {
        window.location.href = `/vidyaonline/VideoContent/${id.id}`;
      } else {
        window.location.href = `/vidhya/${id.categoryName}/${id.id}`;
      }
    } else {
      // this.props.requireLogin()
    }
  }
  render() {
    // console.log(this.state.dataList);
    return (
      <React.Fragment>

        {this.state.dataList.map((value) => (
          <div style={{
            background: "#fff",
            borderTop: "4px solid #fff",
            padding: 20,
            boxShadow: "0px 2px 15px rgb(0 0 0 / 10%)",
            marginTop: "20px",
            transition: "0.3s",
          }}>


            <div className="single-blog-post small-featured-post d-flex">
              <div className="post-thumb">
                <a
                  className="nav-link post-title content-padding"
                  href='#'
                  onClick={() => this.call(value)}
                >
                  <img
                    src={ACCESS_POINT + "/superAdmin/file?fileurl=" + value.file}
                    alt=""
                    className="w-100"
                    style={{}}
                  />
                </a>
              </div>
              <div class="post-data">
                <Link
                  className="nav-link post-title content-padding"
                  onClick={() => this.call(value)}
                >
                  <span className="post-catagory">{value.categoryName}</span>
                </Link>
                <div className="post-meta">
                  <Link
                    className="nav-link post-title content-padding"
                    onClick={() => this.call(value)}
                  >
                    <h6 className="comment-panel-color rightpanel-zeromargin">
                      {value.content1}.
                    </h6>
                  </Link>

                  {/* <p className="post-date" style={{ fontSize: 12 }}> */}
                  {/* <span>7:00 AM</span> | <span>April 14</span>*/}
                  {/* {"Last Updated  :"}
                  {value.Date ? this.getdate(value.Date) : ""} */}
                  {/* </p> */}

                </div>
              </div>
            </div>
            <br />
          </div>
        ))
        }
      </React.Fragment>
    );
  }
}

export default RightPanel;
