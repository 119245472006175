import React from "react";
import {
    Page,
    Text,
    Font,
    Document,
    StyleSheet,
    Image,
    View
} from "@react-pdf/renderer";
import { ACCESS_POINT } from "../../../config";
const ExamplePDF = ({ username, date, quizname, prevalue, postvalue }) => {
    return (
        <Document>
            <Page>
                <Image style={styles.image} src={`/static_images/vidhya-certificate-blank_1642587715635.png`} />
                <View style={[styles.username, styles.box]}>
                    <Text style={[styles.text]}>This is to certify that</Text>
                    <Text style={[styles.text, { fontSize: 20, textAlign: 'center', fontFamily: 'Oswald' }]}>{username}</Text>
                    <Text style={[styles.text]}>has successfully completed E-LEARNING module</Text>
                    <Text style={[styles.text, { textAlign: 'center', fontSize: 18, fontWeight: "bold", fontFamily: 'Oswald' }]}>
                        {quizname} <Text style={{ fontFamily: "Times-Roman", fontSize: 20 }}>on</Text> {date}
                    </Text>
                    <Text style={[styles.text, { wrap: 'nowrap' }]}>This certificate is valid for one year from the date of issue.</Text>
                </View>
            </Page>

        </Document>
    );
};
// Font.register({
//     family: "Arial",
//     src: `https://fonts.googleapis.com/css?family=Roboto`
// });
Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    box: {
        top: 400,
        left: 30,
        alignItems: 'center',
        width: 600,
        height: 200
    },
    text: {
        margin: 5,
        fontSize: 16
        // fontFamily: "Arial"
    },
    username: {
        margin: 12,
        fontSize: 14,
        // textAlign: "justify",      
        // wordWrap: "break-word",
        alignSelf: "center",
        justifyContent: "center",
        fontFamily: "Times-Roman",
        //  fontFamily: "Parisienne-Regular",
        position: "absolute",
        // top: 400,
        // height: 70,
        left: 270,
        width: 10,
        transformOrigin: ' 0 0',
        transform: ' rotate(90deg)'
    },


    module: {
        margin: 12,
        fontSize: 22,
        // textAlign: "justify",
        fontFamily: "Times-Roman",
        //  fontFamily: "Parisienne-Regular",
        position: "absolute",
        top: 400,
        left: 205,
        width: 10,
        transformOrigin: ' 0 0',
        transform: ' rotate(90deg)'
    },
    image: {
        width: "auto",
        height: "830px",
        // transform: ' rotate(270deg)'
        // top: "25%"
    },

});

export default ExamplePDF;
