import React from "react";
import renderHTML from "react-render-html";
import ScriptTag from "react-script-tag";
// import ass1 from ".."

const ThemeSeven = ({ data, userlog }) => {
  console.log("theme 7");
  return (
    <React.Fragment>
      <main id="main" style={{

      }} >
        <div class="breadcrumb-area" data-aos="fade-right" data-aos-delay="100">
          <div class="container-fluid" >
            <div class="row" >
              <div class="col-12" style={{ marginTop: '5%' }}>
                <nav aria-label="breadcrumb" className="smallroute">
                  <ol class="breadcrumb" style={{ marginLeft: '0%' }}>
                    <li class="breadcrumb-item"><a href={"/"}><i class="fa fa-home"></i> &nbsp;Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{data.name}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <br />
        {data && data.name == "Courses List" && data.sheetContent && data.sheetContent.length &&
          <React.Fragment>
            {/* <div class="container-fluid">
              <div class="breadcrumbs" data-aos="zoom-in" data-aos-delay="100">

                <h2> Courses</h2>
              </div>
            </div> */}

            {/* <div className="container-fluid">
              <div className="row" data-aos="zoom-in" data-aos-delay="100">
                <div className="col-sm-2" >
                  <div
                    className="" style={{
                      overflowX: "auto", 
                      // marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/ass1.png" class="img-fluid" alt="sorsssry" />
                  </div>
                </div>
                <div className="col-sm-2" >
                  <div
                    className="" style={{
                      overflowX: "auto",
                      //  marginTop: "55px", paddingTop: "50px"
                       // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/ass2.png" class="img-fluid" alt="sorry" />
                  </div>
                </div>
                <div className="col-sm-2" >
                  <div
                    className="" style={{
                      overflowX: "auto",
                      //  marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/ass3.png" class="img-fluid" alt="sorry" />
                  </div>
                </div>
                <div className="col-sm-2" >
                  <div
                    className="" style={{
                      overflowX: "auto",
                      //  marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/ass4.png" class="img-fluid" alt="sorry" />
                  </div>
                </div>
                <div className="col-sm-2" >
                  <div
                    className="" style={{
                      overflowX: "auto",
                      //  marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/ass5.png" class="img-fluid" alt="sorry" />
                  </div>
                </div>
                <div className="col-sm-2" >
                  <div
                    className="" style={{
                      overflowX: "auto",
                      //  marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/ass6.png" class="img-fluid" alt="sorry" />
                  </div>
                </div>

              </div><br />
              <div className="row" data-aos="zoom-in" data-aos-delay="100">
                <div className="col-sm" >
                  <div
                    className="container-fluid aos-init aos-animate" style={{
                      overflowX: "auto", marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/c1.jpg" style={{ height: "200px", width: "350px" }} class="img-fluid" alt="sorry" />
                    <h6 className="text-center">Soybean Crop</h6>
                  </div>
                </div>
                <div className="col-sm" >
                  <div
                    className="" style={{
                      overflowX: "auto", marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/c2.jpg" style={{ height: "200px", width: "350px" }} class="img-fluid" alt="sorry" />
                    <h6 className="text-center">Paddy Crop</h6>
                  </div>
                </div>
                <div className="col-sm" >
                  <div
                    className="" style={{
                      overflowX: "auto", marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/c3.jpg" style={{ height: "200px", width: "350px" }} class="img-fluid" alt="sorry" />
                    <h6 className="text-center">Cotton Crop</h6>
                  </div>
                </div>
                <div className="col-sm" >
                  <div
                    className="" style={{
                      overflowX: "auto", marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/c4.jpg" style={{ height: "200px", width: "350px" }} class="img-fluid" alt="sorry" />
                    <h6 className="text-center">Fitsol Sugarcane</h6>
                  </div>
                </div>
                <div className="col-sm" >
                  <div
                    className="" style={{
                      overflowX: "auto", marginTop: "55px", paddingTop: "50px"
                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    <img src="/assets/img/c5.png" style={{ height: "200px", width: "350px" }} class="img-fluid" alt="sorry" />
                    <h6 className="text-center">Gromor Power</h6>
                  </div>
                </div>


              </div>
              {userlog && userlog != null && <div className="row" data-aos="zoom-in" data-aos-delay="100">
                <div className="col-sm-12" >
                  <div
                    className=""
                    style={{
                      overflowX: "auto",
                      marginTop: "55px",
                      paddingTop: "50px"

                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    {renderHTML(data.sheetContent)}
                  </div>
                </div>

              </div>}
            </div> */}
          </React.Fragment>}
        {data && data.name == "Videos Section" && data.sheetContent && data.sheetContent.length &&
          <React.Fragment >

            <div className="container">

              <div className="row" data-aos="zoom-in" data-aos-delay="100">
                <div className="col-sm-12" >
                  <div
                    className=""
                    style={{
                      overflowX: "auto",
                      marginTop: "55px",
                      paddingTop: "50px"

                      //   // boxshadow: "0 10px 30px 0 rgba(74, 67, 67, 0.39",
                    }}
                  >
                    {renderHTML(data.sheetContent)}
                  </div>
                </div>

              </div>
            </div>
          </React.Fragment >
        }

      </main>
      <ScriptTag type="text/javascript" src="assets/vendor/swiper/swiper-bundle.min.css" />
    </React.Fragment >
  );
};

export default ThemeSeven;
