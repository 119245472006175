import React, { Component } from 'react';
import Datatable from '../../components/Datatable/Datatable'
import CmsContent from '../../MiddleWare/CmsContent';
import SingleSelect from "../../components/Form/SingleSelect";
import FormMiddleWare from '../../components/Form/FormMiddleware';
import moment from 'moment';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import LoginModal from '../../../components/Modal/Modal';
class Quiztable extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            customerid: JSON.parse(localStorage.getItem('Admin')),
            serviceid: 8,
            page2: 0,
            // userName: localStorage.getItem("userName"),
            userName: JSON.parse(localStorage.getItem("userDetails")).userName,
            userId: localStorage.getItem("userId"),
            userDetails: JSON.parse(localStorage.getItem("userDetails"))
        };
    }

    async componentWillMount() {
        try {
            // let QuizList = await CmsContent.getTwoConditionedValue('tbl_question', 'customerid', this.state.customerid.id, 1, 1, "id as value ,quizName as label,contentJson");
            let QuizList = await CmsContent.getFreedom('tbl_question.id as value,tbl_question.quizName as label,tbl_question.contentJson', 'tbl_question,tbl_quizAnswer', `tbl_question.customerid=${this.state.customerid.id} and tbl_quizAnswer.userId=${this.state.userDetails.id} and tbl_question.id =tbl_quizAnswer.quizId`, 1, 1)
            let data = await CmsContent.getquizlog();

            this.setState({ data: data.data, QuizList: QuizList.data });
        } catch (error) {
            console.error(error);
        }
    }
    column = [
        // {
        //     Header: 'Username',
        //     accessor: 'label',
        //     Cell: d => this.firstFunction(d.original, d.original.label)
        // },
        {
            Header: 'Attempts',
            accessor: 'count',
            Cell: d => this.firstFunction(d.original, d.original.count)
        },
        {
            Header: 'Score',
            accessor: 'score',
            Cell: d => this.firstFunction(d.original, d.original.score)
        },
        {
            Header: 'Total Questions',
            accessor: 'total',
            Cell: d => this.firstFunction(d.original, d.original.total)
        },
        {
            Header: 'View Report',
            accessor: 'total',
            Cell: d => this.firstFunctionButton(d.original, 'View')
        },
        {
            Header: 'Certificate',
            accessor: 'certificate',
            Cell: d => this.deside(d.original)
        },

    ];
    column1 = [
        // {
        //     Header: 'Username',
        //     accessor: 'label',
        // },
        {
            Header: 'Score',
            accessor: 'score',
        },
        // {
        //     Header: 'Post-Score',
        //     accessor: 'posttotal',
        // },
        {
            Header: 'Total Questions',
            accessor: 'total',
        },
        {
            Header: 'Attended on',
            accessor: 'time',
            Cell: d => this.secondFunction(d.original)
        },
        // {
        //     Header: 'Pre/Post',
        //     accessor: 'time',
        //     Cell: d => d.original.prepost ? "Pre & Post" : "Pre"
        // },
        {
            Header: 'View Answer',
            accessor: 'view',
            Cell: d => this.dataTableButton('info', 'View', () => {
                this.ViewOne(d.original);
            })
        },
        // {
        //     Header: 'Certificate',
        //     accessor: 'certificate',
        //     Cell: d => this.deside(d)
        // }
    ];
    column2 = [
        {
            Header: 'Question',
            accessor: 'question',
            Cell: d => this.answerUI(d.original, 'Question')
        },
        {
            Header: 'Response',
            accessor: 'answer',
            Cell: d => this.answerUI(d.original, 'Response')
        },
        // {
        //     Header: 'Post-Response',
        //     accessor: 'postanswer',
        // // },
        {
            Header: 'Correct Answer',
            accessor: 'correct',
            Cell: d => this.answerUI(d.original, 'correct')
        },
    ];
    answerUI = (d, header) => {
        if (header == 'Question') {
            if (d.answer == d.correct) {
                return <p style={{ color: 'green', textAlign: 'center' }}>{d.question}</p>
            } else {
                return <p style={{ color: 'red', textAlign: 'center' }}>{d.question}</p>
            }
        }
        if (header == 'Response') {
            if (d.answer == d.correct) {
                return <p style={{ color: 'green', textAlign: 'center' }}>{d.answer}</p>
            } else {
                return <p style={{ color: 'red', textAlign: 'center' }}>{d.answer}</p>
            }
        }
        if (header == 'correct') {
            // if (d.answer.includes(d.correct)) {
            if (d.answer == d.correct) {
                return <p style={{ color: 'green', textAlign: 'center' }}>{d.correct}</p>
            } else {
                return <p style={{ color: 'red', textAlign: 'center' }}>{d.correct}</p>
            }
        }
    }
    deside = d => {
        let val = d.score * 100 / d.total;
        if (val >= 100) {
            return (this.dataTableButton('success', 'View Certificate', () => {
                this.ViewCertificate(d);
            }))
        } else {
            return <center>Incomplete</center>
        }
    };
    ViewCertificate = d => {
        console.log(d, this.state.selectQuiz.label, d.time);
        window.open(`Certificate/${btoa(this.state.selectQuiz.label)}/${btoa(d.label)}/${btoa(d.time)}/${btoa(d.score * 100 / d.total)}/${btoa(d.posttotal * 100 / d.total)}`, "_blank");
    }
    regEx = (str) => {
        str = str.replace(/\s/g, '');
        return str
    }
    ViewOne = (d) => {
        try {
            console.log(d, 'd');
            let answer = JSON.parse(d.answerJson)
            let question = JSON.parse(this.state.selectQuiz.contentJson);
            let postAnswer = d.postAns ? JSON.parse(d.postAns) : null

            let finalSingleValue = [];
            let con = false;
            question.map((ival, i) => {
                answer.map((mval, ind) => {
                    if ((ival.questionId == mval.questionId) && (ival.questionId != 'undefined') && (mval.questionId != null)) {
                        console.log(ival.questionId, mval.questionId);
                        //         con = true;
                        //     }else{
                        //         con = false;
                        //     }            
                        // if(con){
                        if (ival.type === 'multiple') {
                            let ans1 = [];
                            answer[ind].answer.map((jval, j) => {
                                let ans = jval.split('option').pop();
                                ans1.push(ival.options[ans - 1]);
                            });
                            let postAns = [];
                            if (postAnswer && postAnswer[ind].answer) {
                                postAnswer[ind].answer.map((jval, j) => {
                                    let ans = jval.split('option').pop();
                                    postAns.push(ival.options[ans - 1]);
                                });
                                postAns = postAns.toString();
                            } else {
                                postAns = '-';
                            }

                            let actualAnswer = [];
                            if (ival.answer) {
                                ival.answer.split(',').map((jval, j) => {
                                    let cA = jval.split('option').pop();
                                    actualAnswer.push(ival.options[cA - 1]);
                                });
                            }

                            finalSingleValue.push({ question: ival.question, answer: ans1.toString().toLowerCase(), correct: actualAnswer.toString().toLowerCase(), postanswer: postAns, result: ans1.toString().toLowerCase() === actualAnswer.toString().toLowerCase() ? 'Correct' : "Wrong" });
                        } else {

                            let postAns = (postAnswer && postAnswer[ind] && postAnswer[ind].answer) ? postAnswer[ind].answer.toLowerCase() : '-';
                            finalSingleValue.push({ question: ival.question, answer: answer[ind].answer.toLowerCase(), correct: ival.answer.toLowerCase(), postanswer: postAns, result: answer[ind].answer.toLowerCase() == ival.answer.toLowerCase() ? 'Correct' : "Wrong" })
                        }
                    }
                });
            });
            // this.resetState();
            console.log(finalSingleValue, 'finalval');
            this.setState({ finalSingleValue, page2: 2, prePost: d.prepost ? 'Pre' : 'Post', date: this.secondFunction(d) });

        } catch (error) {
            console.log(error);
        }
    }
    firstFunction = (d, value) => {
        return <p href="#" style={{ textAlign: 'center', cursor: "pointer" }} onClick={() => { this.doFunction(d) }}>{value}</p>
    }
    firstFunctionButton = (d, value) => {
        return <button class='btn btn-info' style={{ textAlign: 'center' }} onClick={() => { this.doFunction(d) }}>{value}</button>
    }
    secondFunction(date) {

        // return <p style={{ textAlign: 'center' }}>

        return moment(date.time).format('DD-MM-YYYY')
        // </p>
    }
    doFunction = async (d) => {
        try {
            let selectQuiz = this.state.selectQuiz;
            let singleUser = await CmsContent.getFreedom(
                'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson,tbl_quizAnswer.prepost,tbl_quizAnswer.postanswer as postAns,tbl_quizAnswer.posttotal',
                'tbl_quizAnswer,tbl_user_web',
                `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizId=${selectQuiz.value} and tbl_quizAnswer.userId=${d.value} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
                'tbl_quizAnswer.id',
                'tbl_quizAnswer.id DESC');
            this.setState({ page2: 1, singleUser: singleUser.data });
        } catch (error) {
            console.error(error);
        }
    }
    // doFunction = async (d) => {
    //     try {
    //         let selectQuiz = this.state.selectQuiz;
    //         let singleUser = await CmsContent.getFreedom(
    //             'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson,tbl_quizAnswer.prepost,tbl_quizAnswer.postanswer as postAns,tbl_quizAnswer.posttotal',
    //             'tbl_quizAnswer,tbl_user_web',
    //             `tbl_user_web.id=tbl_quizAnswer.userId  and tbl_quizAnswer.userId=${this.state.userId} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
    //             'tbl_quizAnswer.id',
    //             'tbl_quizAnswer.id DESC');
    //         this.setState({ page2: 1, singleUser: singleUser.data, selectQuiz });
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }
    // resetState = () => this.setState({ page2: 1, singleUser: [], finalSingleValue: [], date: [] })
    // selectQuiz = async selectQuiz => {
    //     try {
    //         let UserList = await CmsContent.getFreedom(
    //             'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson,tbl_quizAnswer.prepost,tbl_quizAnswer.postanswer as postAns,tbl_quizAnswer.posttotal',
    //             'tbl_quizAnswer,tbl_user_web',
    //             `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizid=${selectQuiz.value}  and tbl_quizAnswer.userId=${this.state.userId} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
    //             'tbl_quizAnswer.id',
    //             'tbl_quizAnswer.id DESC');
    //         this.resetState();
    //         console.log(UserList.data);
    //         this.setState({ selectQuiz, singleUser: UserList.data });
    //         // UserList.data.map((ival, i) => {
    //         //     if (ival.value == this.state.userId) {
    //         //         this.doFunction()
    //         //     }
    //         // })
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    resetState = () => this.setState({ page2: 0, singleUser: [], finalSingleValue: [], date: [] })
    selectQuiz = async selectQuiz => {
        try {
            let UserList = await CmsContent.getFreedom(
                'tbl_user_web.userName as label,tbl_quizAnswer.userId as value, count(tbl_quizAnswer.userId) as count,SUM(tbl_quizAnswer.total) as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time',
                'tbl_quizAnswer,tbl_user_web',
                `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.userId=${this.state.userDetails.id} and tbl_quizAnswer.quizId=${selectQuiz.value} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
                'tbl_quizAnswer.userId',
                'tbl_quizAnswer.id DESC');

            this.resetState();
            this.setState({ selectQuiz, UserList: UserList.data });
        } catch (error) {
            console.error(error);
        }
    };
    OnBack = val => {
        this.setState({ page2: val - 1 })
    }
    render() {
        console.log(this.state.userName, 'userName');
        const { UserList, QuizList, page2, singleUser, finalSingleValue, userName, prePost, date } = this.state;
        var tableData = null
        var column = null
        if (page2 === 0) {
            tableData = UserList;
            column = this.column;
        } else if (page2 === 1) {
            tableData = singleUser;
            column = this.column1;
        } else if (page2 === 2) {

            tableData = finalSingleValue;
            column = this.column2;
        }
        console.log(tableData, page2);
        return (
            <React.Fragment>
                <div class="breadcrumb-area" data-aos="fade-right" data-aos-delay="100">
                    <div class="container-fluid" >
                        <div class="row" >
                            <div class="col-12" style={{ marginTop: '5%' }}>
                                <nav aria-label="breadcrumb" className="smallroute">
                                    <ol class="breadcrumb" style={{ marginLeft: '0%' }}>
                                        <li class="breadcrumb-item"><a href={"/"}><i class="fa fa-home"></i>&nbsp;Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Quiz Report</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <br /> */}
                {/* <div class="container-fluid">
                    <div class="breadcrumbs" data-aos="zoom-in" data-aos-delay="100">

                        <h2>Quiz Report</h2>
                    </div>
                </div><br /> */}
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card" style={{ boxShadow: '0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2)' }}>
                                    {/* <div className="card-header" style={{ backgroundColor: '#5fcf80' }}>
                                        <h3>Report</h3>
                                    </div> */}
                                    <div className="card-body" style={{ minHeight: 350 }}>
                                        <div className="row form-group">
                                            <div className="col-sm-1" />
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1">Select Course to view report</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect options={QuizList} handleChange={this.selectQuiz} value={this.state.box} placeholder="Select Quiz" />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group ml-0 mb-0">
                                            <div className="col-sm-1 p-0" >
                                                {page2 !== 0 && this.dataTableButton('danger', 'Back', () => {
                                                    this.OnBack(page2);
                                                })}
                                            </div>
                                            {/* <PDFDownloadLink
                      style={{ color: 'white' }}
                      document={<QuizCertificate />}
                      fileName="sample.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download Pdf'
                      }
                      <button className="btn btn-success">
                        Download Table Pdf
                    </button>
                    </PDFDownloadLink> */}
                                            <div className="col-sm-11 h2"><center>{page2 === 2 && `${userName}'s response on ${date}`}</center></div>
                                        </div>

                                        {
                                            (page2 === 0 && tableData && tableData.length) ?
                                                <div class="card text-center" >
                                                    <div class="card-header">
                                                        <h5 class="card-title">{this.state.selectQuiz.label}</h5>
                                                    </div>
                                                    <div class="card-body">

                                                        <h6 class="card-subtitle mb-2">Attempts: {tableData[0].count}</h6>
                                                        <h6 class="card-subtitle mb-2 ">Score: {tableData[0].score}</h6>
                                                        <h6 class="card-subtitle mb-2">Total: {tableData[0].total}</h6>

                                                        <a href="#" class="card-link">{this.firstFunctionButton(tableData[0], 'View Response')}</a>
                                                        <a href="#" class="card-link">{this.deside(tableData[0])}</a>
                                                    </div>
                                                    <div class="card-footer text-muted">
                                                        {moment(tableData[0].time).format('DD-MMM-YYYY HH:MM:SS')}
                                                    </div>
                                                </div>

                                                :
                                                tableData && <Datatable data={tableData} columnHeading={column} />
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </React.Fragment>
        );
    }
}

export default Quiztable;
