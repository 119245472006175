// import http from "./httpMiddleWare";
// import { ACCESS_POINT } from "../config";
import http from "./httpMiddleWare";
import { ACCESS_POINT } from "../config";
import moment from "moment";

//import { async } from 'q';

const themeInsert = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};
/////////REDIS DATA///////////////////////////////////
const getHeader = async (themeid) => {
  console.log(themeid, "themeid");
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/headNav/${themeid}`
  );
  return result;
};

const getpublication = async (catid) => {
  console.log("catid", catid);
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/publication/${catid}`
  );
  return result;
};

const getThemebyid = async (themeid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getPage/${themeid}`
  );
  return result;
};

const getRightpanel = async (themeid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getRightpanel/${themeid}`
  );
  return result;
};
function compareValues(key, type, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = type === "int" ? parseInt(a[key]) : a[key].toUpperCase();
    const varB = type === "int" ? parseInt(b[key]) : b[key].toUpperCase();

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    //console.log(comparison);
    return order === "desc" ? comparison * -1 : comparison;
  };
}
const getvideocount = async (customerid, id, d) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videocount/${customerid}/${id}`
  );
  return result;
};
const getdiff = async (customerid, id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/evaluation/${customerid}`
  );
  return result;
};
const getquiz = async (customerid, id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quiz/${customerid}`
  );
  return result;
};

const getDetail = async (customerid, id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getDetail/${customerid}/${id}`
  );

  return result;
};

const getList = async (id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/Coromandel/listmore/${id}`);
  return result;
};

const getSearch = async (customerid, values) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/ehsearch/${customerid}/${values}`
  );
  return result;
};

/////////////////////////////////////////////////////////////

const addMaster = async (tableName, categoryArray) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/master/${tableName}`,
    categoryArray
  );
  return result;
};

// const addContentMaster = async (tableName, categoryArray) => {
//   const result = await http.post(ACCESS_POINT + `/cmsContent/addContentMaster/${tableName}`, categoryArray);
//   return result;
// };

const updateMaster = async (tableName, id, categoryArray, column = "id") => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${column}`,
    { id: id, categoryArray }
  );
  return result;
};

const deleteMaster = async (tableName, id) => {
  const result = await http.delete(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${id}`
  );
  return result;
};

const getMasterValues = async (tableName, columName = "id", order = "desc") => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${columName}/${order}`
  );
  return result;
};

const getMasteractive = async (tableName) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/masteractive/${tableName}`
  );
  return result;
};

const getThemes = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/themes`);
  return result;
};

const getThemeById = async (themeid) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/${themeid}`);
  return result;
};

const getVideoContentById = async (themeid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoContent/${themeid}`
  );
  return result;
};

const getAllMappedpages = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/dynamic/page`);
  return result;
};

const getAllPages = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/dynamic/pages`);
  return result;
};

const updatePage = async (data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/pages`,
    data
  );
  return result;
};

const updateLoginStatus = async (data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateLoginStatus/pages`,
    data
  );
  return result;
};

const getAllVideos = async (type, companyid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getContent/getAllVideos/${type}/${companyid}`
  );
  return result;
};

const checkVideoCount = async (videoId, videoName) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/dynamic/viewCount/${videoId}/${videoName}`
  );
  return result;
};

const updateCount = async (id, count) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/viewCount/${id}`,
    count
  );
  return result;
};

const rightPanelAdd = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const getRightPanelList = async (tableName) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}`
  );
  return result;
};

const getAllRightPanelList = async (tableName) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/rightPanel/dynamic/list`
  );
  return result;
};

const getAllSerchKeyValue = async (values) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/dynamic/search/${values}`
  );
  return result;
};

const subTitleAdd = async (formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/subTitleMaster/subTitleAdd`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const getSubTitleValues = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/subTitleMaster/subTitleAdd`
  );
  return result;
};

const getAllSubtitleList = async (tablename) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/subTitleMaster/getAllList/${tablename}`
  );
  return result;
};

const getAllSubtitleLists = async (tablename, columnName, order) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/subTitleMaster/getAllLists/${tablename}/${columnName}/${order}`
  );
  return result;
};

const getVideoListForAdmin = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getVideoListForAdmin/videoList`
  );
  return result;
};

const addQuizContent = async (jsonContent) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/quizContent/addQuiz`,
    jsonContent
  );
  return result;
};

const getQuizQuestion = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quizContent/selectBox`
  );
  return result;
};

//ram
const insertImage = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/library/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const getLibraryImage = async (type) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/library/${type}`);
  return result;
};

const updateQuizAnswer = async (quizAnswerJson, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/quizAnswer/${tableName}`,
    quizAnswerJson
  );
  return result;
};

const checkQuizAttended = async (userId, quizId) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quizAnswer/${userId}/${quizId}`
  );
  return result;
};

const getScoreOfUser = async (userId) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/userScore/${userId}`
  );
  return result;
};

const updateCategory = async (data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/category`,
    data
  );
  return result;
};

const updateCategorys = async (datas) => {
  const result = await http.put(ACCESS_POINT + `/cmsContent/master`, datas);
  return result;
};

const videoSeekLog = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoSeekLog/seekLog`
  );
  return result;
};

const getquizlog = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quizlogcontent/quizlog`
  );
  return result;
};

const displayQuizQuestion = async (value, customerid) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/quizContent/selectBox`,
    { value, customerid }
  );
  return result;
};

const getAllHomePanelList = async (tableName) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/homePanel/${tableName}`
  );
  return result;
};

const authorinsert = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const authorinserts = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addauthors/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const updateMasters = async (formdata, tableName) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateMasters/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const getauthorvalues = async (tableName) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`
  );
  return result;
};

const getAllCategoryMappedPages = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getallmappedpages/toCategory`
  );
  return result;
};
const updateauthor = async (tableName, categoryArray) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`,
    categoryArray,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};
const getCompanyUsers = async (companyid, usertype, serviceid = "false") => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getusers/${companyid}/${usertype}/${serviceid}`
  );
  return result;
};
const UpdateOrder = async (id, listingorder) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateorder/${id}`,
    { listingorder }
  );
  return result;
};

const videoList = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/selectbox/video`);
  return result;
};

const videoValue = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/videoValue/json`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const videoAnalyticsList = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoAnalyticsList/json`
  );
  return result;
};

const videoJsonList = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoJsonList/json/${id}`
  );
  return result;
};

const getPagesData = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getpages/getpagesdata/${id}`
  );
  return result;
};

const videoUrl = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoUrl/json/${id}`
  );
  return result;
};

const videoObjectTracking = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/videoObjectTracking/json`,
    data
  );
  return result;
};

const getLikesLogs = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/dynamic/likelog`);
  return result;
};

const getConditionedValuewithStatus = async (
  tablename,
  columname,
  value,
  id = "id",
  name = "name"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getConditionedValuewithStatus/${tablename}/${columname}/${value}/${id}/${name}`
  );
  return result;
};

const getTwoConditionedValue = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getTwoConditionedValue/alternatestatus",
    { tableName, columName, value, columName1, value1, val }
  );
  return result;
};

const getTwoConditionedValueFree = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getTwoConditionedValueFree/alternatestatus",
    { tableName, columName, value, columName1, value1, val }
  );
  return result;
};

const getThreeConditionedValue = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  columName2,
  value2,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getThreeConditionedValue/alternatestatus",
    { tableName, columName, value, columName1, value1, columName2, value2, val }
  );
  return result;
};
const getThreeConditionedValueFree = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  columName2,
  value2,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getThreeConditionedValueFree/alternatestatus",
    { tableName, columName, value, columName1, value1, columName2, value2, val }
  );
  return result;
};

const getInnerJoinList = async (value) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getInnerJoinList/getInnerJoin`,
    value
  );
  return result;
};
const getInnerJoinListwithStatus = async (
  tablename1,
  columname1,
  tablename2,
  columname2
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getInnerJoinListwithStatus/${tablename1}/${columname1}/${tablename2}/${columname2}`
  );
  return result;
};

const learningObjectSubmit = async (tableName, formdata) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/learningObjectSubmit/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};
const getUserJson = async (id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/getUserJson/${id}`);
  return result;
};
const mapUserToResource = async (tableName, formdata) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/mapUserToResource/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};
const getSingleConditionedValue = async (
  tableName,
  columname,
  value,
  dothis = "SELECT *"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSingleConditionedValue/${dothis}/${tableName}/${columname}/${value}`
  );
  return result;
};

const getgroupbyvalue = async (tableName, columname, type, value) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getgroupbyvalue/${tableName}/${columname}/${type}/${value}`
  );
  return result;
};
const getSingleConditioned = async (
  tableName,
  columName1,
  value,
  dothis = "SELECT *"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSingleConditioned/${dothis}/${tableName}/${columName1}/${value}/`
  );
  return result;
};

const mappingportlet = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/mappingportlet/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const getLearner = async (
  tablename,
  columname,
  value,
  id = "id",
  name = "name",
  companyid
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getLearner/${tablename}/${columname}/${value}/${id}/${name}/${companyid}`
  );
  return result;
};

const getColoumtablevalue = async (
  tablename,
  columname,
  customerid,
  type,
  secondcolumn
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/gettwocolumnvalue/${tablename}/${columname}/${customerid}/${type}/${secondcolumn}`
  );
  return result;
};

const getgroupbycohorts = async (tablename, columname, value, columname1) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getgroupbycohorts/${tablename}/${columname}/${value}/${columname1}`
  );
  return result;
};

const getValuebyColumn = async (
  tablename,
  columname,
  value,
  columname1,
  value1
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getValuebyColumn/${tablename}/${columname}/${value}/${columname1}/${value1}`
  );

  return result;
};

const updateBox = async (tableName, formdata) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateBox/${tableName}`,
    formdata
  );

  return result;
};

const valueCustomerId = async (boxId, customerid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/valuecompanyid/${boxId}/${customerid}`
  );

  return result;
};
const getSelectvalue = async (
  tableName,
  column,
  companyid,
  id = "id",
  name = "name"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSelectvalue/${tableName}/${column}/${companyid}/${id}/${name}`
  );

  return result;
};

const getLeanersFromCohorts = async (value) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getLeanersFromCohorts/getLearners`,
    { id: value }
  );

  return result;
};
const getFreedom = async (
  select,
  tableName,
  condition,
  groupby = "id",
  orderby = "id"
) => {
  let value = {};
  value.select = select;
  value.tableName = tableName;
  value.condition = condition;
  value.groupby = groupby;
  value.orderby = orderby;
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getFullFreedom/getFreedom`,
    value
  );
  return result;
};

const chan = async (tn1, b1, tn2, b2) => {
  let value = {};
  value.tableName1 = tn1;
  value.body1 = b1;
  value.tableName2 = tn2;
  value.body2 = b2;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/putArrayLoop/putSolo`,
    value
  );
  return result;
};
const chanAnswer = async (tn1, b1, p1, u1) => {
  let value = {};
  value.tableName = tn1;
  value.body = b1;
  value.pageid = p1;
  value.user = u1;
  console.log(value)
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/FormUserInsert/putSolo`,
    value
  );
  return result;
};

// const chanAnswer = async (tn1, b1) => {
//   let value = {};
//   value.tableName = tn1;
//   value.body = b1;
//   const result = await http.put(
//     ACCESS_POINT + `/cmsContent/putArrayLoop/putSolo`,
//     value
//   );
//   return result;
// };

// const addpageupdate = async (tablename, data) => {
//   let value = {};
//   value.tablename = tablename;
//   value.data = data;

//   const result = await http.post(ACCESS_POINT + `/cmsContent/addpageupdate/update`, data);
//   return result;
// };

const addpageupdate = async (tableName, formdata, index, pageid, currentid) => {
  const result = await http.post(
    ACCESS_POINT +
    `/cmsContent/addpageupdate/update/${tableName}?index=${index}&pageid=${pageid}&currentid=${currentid}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};

const addpagemodelwindow = async (tableName, formdata, pageid) => {
  const result = await http.post(
    ACCESS_POINT +
    `/cmsContent/addpagemodelwindow/update/${tableName}?pageid=${pageid}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return result;
};
const addSurveyContent = async (jsonContent) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertSurvey/addSurvey`,
    jsonContent
  );
  return result;
};
const addSurveyQuestion = async (jsonContent) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertQuestionSurvey/addSurveyQuestion`,
    jsonContent
  );
  return result;
};
const addSurveyAnswer = async (tn1, b1) => {
  let value = {};
  value.tableName = tn1;
  value.body = b1;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertResultSurvey/addSurveyAnswer`,
    value
  );
  return result;
};

const getPerformanceForCohort = async (d) => {
  const result = await http.post(
    ACCESS_POINT + "/cmsContent/getPerformanceForCohort/ForCohort",
    d,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};
const getoverallTopperdetails = async (d) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/getoverallTopperdetails/${d}`
  );
  return result;
};
const sectionContents = async (tableName, data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/sectionContents/${tableName}`,
    data,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};
// const PortletAdded = async data => {
//   const result = await http.get(
//     ACCESS_POINT + `/cmsContent/portlets/Added`,
//     data,
//     { headers: { "Content-Type": "multipart/form-data" } }
//   );
//   return result;
// };
const PortletAdded = async (categoryArray, type) => {
  var id = categoryArray.userId;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/portlets/${type}/${id}`,
    categoryArray
  );
  return result;
};
const getIP = async () => {
  const result = await http.get('https://geolocation-db.com/json/')
  result.data.ip = result.data.IPv4
  result.data.time = moment().format('YYYY-MM-DD HH:MM:SS');
  // const result = await http.post(ACCESS_POINT + `/cmsContent/getIP`);
  return result;
};
const pageViewCount = async (d) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/getContent/pageViewCount`,
    d,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};
const insertMaster = async (t, d) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertMaster/${t}`,
    d
  );
  return result;
};
const GetAdminDetails = async id => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/AdminDetail/${id}`);
  return result;
};
const samplesitepage = async (customerid, id) => {
  console.log("customerid", customerid, "id", id);
  const result = await http.get(ACCESS_POINT + `/cmsContent/samplesitepage/${customerid}/${id}`);
  return result;
};
const getOtp = async (mobile, from) => {
  const result = await http.put(ACCESS_POINT + `/user/commonLogin/${mobile}/${from}`);
  return result;
};

const loginOTPCheckPost = async (actualNum, mobileNumber) => {
  const result = await http.post(ACCESS_POINT + `/user/mobileOTPCheck/${actualNum}`, { num: mobileNumber });
  return result;
};

const search = async themeid => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/search/${themeid}`);
  return result;
};
const createMasterValue = async (tableName, masterValue) => {
  const result = await http.post(ACCESS_POINT + `/superAdmin/dynamic/${tableName}`, masterValue);
  return result;
};
const createcaptcha = async () => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/createcaptcha/captcha`
  );
  return result;
};

const getViewCount = async id => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/coromandel/getViewCount/${id}`);
  return result;
};
const getCoursesList = async (data, id) => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/Coromandel/listmore/${id}`, data);
  return result;
};
const VidyaQuizAttended = async (quizid, userid, where = 'flase', page = 'false') => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/Coromandel/VidyaQuizAttended?userid=${userid}&quizid=${quizid}&where=${where}&pageid=${page}`);
  return result;
};
const insertQuizAnswer = async (quizAnswerJson, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertquizAnswer/${tableName}`,
    quizAnswerJson
  );
  return result;
};
export default {
  getViewCount,
  themeInsert,
  getList,
  getOtp,
  search,
  createcaptcha,
  loginOTPCheckPost,
  GetAdminDetails,
  createMasterValue,
  // addContentMaster,
  samplesitepage,
  getThemes,
  getThemeById,
  addMaster,
  getMasterValues,
  getAllMappedpages,
  getAllPages,
  updatePage,
  getThemebyid,
  updateLoginStatus,
  getAllVideos,
  checkVideoCount,
  updateCount,
  rightPanelAdd,
  getRightPanelList,
  getAllRightPanelList,
  getVideoContentById,
  getAllSerchKeyValue,
  subTitleAdd,
  getSubTitleValues,
  getAllSubtitleList,
  getAllSubtitleLists,
  getVideoListForAdmin,
  addQuizContent,
  getDetail,
  getQuizQuestion,
  insertImage,
  getSearch,
  updateQuizAnswer,
  checkQuizAttended,
  getLibraryImage,
  getScoreOfUser,
  updateCategory,
  updateCategorys,
  updateMaster,
  deleteMaster,
  videoSeekLog,
  displayQuizQuestion,
  getAllHomePanelList,
  authorinsert,
  authorinserts,
  getHeader,
  updateMasters,
  getAllCategoryMappedPages,
  getauthorvalues,
  updateauthor,
  getCompanyUsers,
  UpdateOrder,
  videoList,
  videoValue,
  videoAnalyticsList,
  videoJsonList,
  videoUrl,
  videoObjectTracking,
  getLikesLogs,
  getMasteractive,
  getquizlog,
  getPagesData,
  getConditionedValuewithStatus,
  getInnerJoinListwithStatus,
  getInnerJoinList,
  learningObjectSubmit,
  getUserJson,
  mapUserToResource,
  getSingleConditionedValue,
  getSingleConditioned,
  mappingportlet,
  getLearner,
  getgroupbyvalue,
  getgroupbycohorts,
  getColoumtablevalue,
  getValuebyColumn,
  getdiff,
  getquiz,

  updateBox,
  valueCustomerId,
  getpublication,
  getSelectvalue,
  getTwoConditionedValue,
  getTwoConditionedValueFree,
  getThreeConditionedValue,
  getThreeConditionedValueFree,
  getLeanersFromCohorts,
  getFreedom,
  chan,
  chanAnswer,
  getvideocount,
  addpageupdate,
  addpagemodelwindow,
  addSurveyContent,
  addSurveyQuestion,
  addSurveyAnswer,
  getPerformanceForCohort,
  getoverallTopperdetails,
  sectionContents,
  PortletAdded,
  getIP,
  pageViewCount,
  insertMaster,
  getRightpanel,
  compareValues,
  getCoursesList,
  VidyaQuizAttended,
  insertQuizAnswer
};
