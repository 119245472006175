import React from "react";
///import renderHTML from "react-render-html";
// import { ACCESS_POINT } from "../../../config";
// import SingleSelect from "../../Components/SingleSelect";
import { Alert } from "reactstrap";
const ThemeEight = ({
  listform,
  data,
  Cap = false,
  json,
  dataimage,
  number = 0,
  captchacapture,
  getCaptcha,
  errorcaptchacapture,
  formSubmit,
  alert,
  textalert,
  color,
  onDismiss
}) => {

  console.log('theme 8');
  // console.log("Contact", data, json);
  return (
    <React.Fragment>
      <div class="breadcrumb-area" data-aos="fade-right" data-aos-delay="100">
        <div class="container-fluid" >
          <div class="row" >
            <div class="col-12" style={{ marginTop: '5%' }}>
              <nav aria-label="breadcrumb" className="smallroute">
                <ol class="breadcrumb" style={{ marginLeft: '0%' }}>
                  <li class="breadcrumb-item"><a href={"/"}><i class="fa fa-home"></i>&nbsp;Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{data.name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="breadcrumb-area">
        <div class="container-fluid">
          <div class="row">
            <div
              class="about-us-area section-padding-0-100"
              style={{ width: "100%", marginTop: "2%" }}
              data-aos="zoom-in" data-aos-delay="100"
            >
              <div class="container container-margin-top">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="about-content"
                      style={{
                        marginTop: "17px",
                        paddingTop: "50px",
                        padding: "55px",
                        boxShadow: '0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2)'

                      }}
                    >
                      <div class="section-heading text-center">
                        <h2 class="post-title" style={{ color: "#191919" }}>
                          {data.name}
                        </h2>
                      </div>
                      {number == 0 ? (
                        <div>
                          {json && json.length && json}
                          {dataimage && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">
                                  Enter Captcha
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="captchacapture"
                                  name="captchacapture"
                                  placeholder="Enter captcha"
                                  value={captchacapture}
                                  onChange={(e) => getCaptcha(e)}
                                />
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {errorcaptchacapture}{" "}
                                </span>
                              </div>
                              {/* <div className="col-sm-1" /> */}
                              <div className="col-sm-3">
                                <svg
                                  style={{
                                    // width: 100,
                                    height: 40,
                                    marginLeft: -25,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: dataimage,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={formSubmit}
                                disabled={alert}
                              >
                                Submit
                              </button>

                              {/* {Cap === false && (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={listform}
                                  style={{ marginLeft: "10px" }}
                                >
                                  List
                                </button>
                              )} */}
                              <br />
                              {/* {
                                alert == true && (
                                  <span >{textalert}</span>
                                )
                              } */}
                              {alert && <div style={{ display: 'block', opacity: 1 }} class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog" role="document" style={{ marginTop: '40px' }}>
                                  <div class="modal-content">
                                    <div class="modal-body" style={{ backgroundColor: '#5fcf80' }}>
                                      <div class="ownaddlic">
                                        <h4>Success</h4>
                                        <p class="">Your query has been submitted. We shall get back to you soon!</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {/*this.state.datatable*/}
                          {/*(this.state.fullData && this.state.fullData.length) ? (<Datatable data={this.state.fullData} columnHeading={this.state.questionDataColumn} />) : null*/}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThemeEight;
