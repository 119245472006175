import React, { Component } from "react";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import CmsContent from "../../MiddleWare/CmsContent";
import http from "../../MiddleWare/httpMiddleWare";
import renderHTML from "react-render-html";
import GallerPop from "./galleryPop";
import { ACCESS_POINT } from "../../config";
import ScriptTag from "react-script-tag";
import './testimonial.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            themeid: this.props.match.params.id,
            pageList: "",
            themeid: "",
            InnerData: [],
            popup: false
        }
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }
    async componentWillReceiveProps(nextProps) {
        window.scrollTo(0, 0);
        await this.getContent(nextProps.match.params.id);
    }
    async componentDidMount() {
        await this.getContent(this.props.match.params.id);
    }
    getContent = async (themeid) => {
        this.setState({ loadcomplete: true })
        localStorage.setItem("themeId", this.props.match.params.id);
        const result = await CmsContent.getThemebyid('643');
        let sheetContent = JSON.parse(result.data[0].sheetContent)
        sheetContent.map((ival, i) => {
            ival.description = ival.description.split('rgb(255,255,255)').join('#e7e2e2')
        })

        this.setState({
            // InnerData: JSON.parse(result.data[0].sheetContent),
            InnerData: sheetContent,
            pageList: result.data[0]
        })
    }
    NextArrow = (props) => {
        const { className, style, onClick, carouselSettings } = props;
        return (
            <div
                className={className}
                style={{ ...carouselSettings, display: "block", background: "red" }}
                onClick={onClick}
            />
        );
    }

    PrevArrow = (props) => {
        const { className, style, onClick, carouselSettings } = props;
        return (
            <div
                className={className}
                style={{ ...carouselSettings, display: "block", background: "green" }}
                onClick={onClick}
            />
        );
    }
    toggle = (a, d = null, Header = null) => {
        if (a == 1) {
            this.setState({ popup: true, popupData: d, modalHeader: Header })
            this.pause()
        } else {
            this.setState({ popup: false, popupData: null, modalHeader: null })
            this.play()
        }
    }
    play() {
        this.slider.slickPlay();
    }
    pause() {
        this.slider.slickPause();
    }
    render() {
        const { pageList, InnerData, date, popup } = this.state;
        // console.log(window.screen.width, "width")

        // console.log(InnerData, 'pageList');
        // const settings = {
        //     className: "center",
        //     centerMode: true,
        //     infinite: true,
        //     centerPadding: "10px",
        //     slidesToShow: 3,
        //     autoplay: true,
        //     autoplaySpeed: 3000,
        //     speed: 500,
        //     responsive: [{
        //         breakpoint: 600,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             initialSlide: 1
        //         }
        //     }],
        //     nextArrow: <this.NextArrow />,
        //     prevArrow: <this.PrevArrow />
        // };
        var carouselSettings = {
            speed: 500,
            dots: true,
            centerMode: true,
            infinite: true,
            slidesToShow: 3,
            centerPadding: "10px",
            // autoplaySpeed: 3000,
            // adaptiveHeight: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 1500,
            autoplayspeed: 1500,
            autoPlay: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        dots: true,
                        speed: 500,
                        autoplay: true,
                        // arrows: true,
                        infinite: true,
                        slidesToShow: 1,
                        centerPadding: "1px",
                        slidesToScroll: 1,
                        initialSlide: 1,
                        swipeToSlide: true,
                        autoplaySpeed: 2000,
                    }
                },
                {
                    breakpoint: 992,
                    settings: 'unslick',
                }
            ],
            // nextArrow: <this.NextArrow />,
            // prevArrow: <this.PrevArrow />
        };
        return (
            <React.Fragment>

                <div class="breadcrumb-area" data-aos="fade-right" data-aos-delay="100">
                    <div class="container-fluid" >
                        <div class="row" >
                            <div class="col-12" style={{ marginTop: '5%' }}>
                                <nav aria-label="breadcrumb" className="smallroute">
                                    <ol class="breadcrumb" style={{ marginLeft: '0%' }}>
                                        <li class="breadcrumb-item"><a href={"/"}><i class="fa fa-home"></i>&nbsp;Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">{pageList.name}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {/* <div class="container-fluid">
                    <div class="breadcrumbs" data-aos="zoom-in" data-aos-delay="100">

                        <h2>{pageList.name}</h2>
                    </div>
                </div><br /> */}
                <div className="container" id="testimonialcard">
                    <div className="row">
                        <div className="col" >
                            <Slider ref={slider => (this.slider = slider)} {...carouselSettings}>
                                {InnerData && InnerData.map((ival) => {
                                    // console.log(ival.title, "titile")
                                    return (
                                        <div className="sliderset" style={{ width:"90%" }}>
                                            <figure class="snip1192" data-aos="zoom-in" data-aos-delay="100" style={{ marginBottom: "50px", width:"90%", marginLeft:"15px", marginRight:"5px"}} >
                                                <blockquote>
                                                    <p className='truncate' style={{ maxHeight: ival.title && ival.title == "RAJASHEKHAR G.BADIGER," ? "110px" : ival.title == "G.V. SUBBA REDDY" ? "230px" : "250px", height: ival.files[1] ? 150 : 280 }}>
                                                        {renderHTML(ival.description)}
                                                    </p>
                                                    <a href='#' id="hov" style={{ fontSize: "small", textDecoration: "underline" }} onClick={() => this.toggle(1, ival.description, ival.title)} >Read in full view...</a>
                                                    {/* <br /> */}
                                                    <div className="d-flex justify-content-center" >
                                                        {console.log(ival.files[1],"ival.files[1].Img ")}
                                                        {ival && ival.files && ival.files[1] && ival.files[1].Img && <img src={'/static_images/' + ival.files[1].Img.split("/")[1]} alt="sq-sample1" />}
                                                    </div>
                                                    <div className="justify-content-bottom" >
                                                        <h1 className="text-left"><b>{ival.title}</b></h1>
                                                        <p className="text-left" >{ival.files[0].name}</p>
                                                    </div>
                                                    <div class="author" >
                                                        {console.log(ival.files[0].Img,"ival.files[0].Img")}
                                                        <img src={'/static_images/' + ival.files[0].Img.split("/")[1]} alt="sq-sample1" />
                                                    </div>
                                                </blockquote>

                                            </figure>
                                        </div>
                                    )
                                })}
                            </Slider><br /><br />
                        </div>
                    </div>
                </div >
                {this.state.popup && <MDBContainer >
                    <MDBModal className="" isOpen={this.state.popup} toggle={() => this.toggle(2)} centered>
                        <MDBModalHeader closeAriaLabel='open' toggle={() => this.toggle(2)} style={{ color: 'rgb(60, 93, 172)' }}>
                            {this.state.modalHeader}
                        </MDBModalHeader>
                        <MDBModalBody >
                            {this.state.popupData ? renderHTML(this.state.popupData) : null}
                        </MDBModalBody>
                    </MDBModal>

                </MDBContainer>}

                {/* <ScriptTag type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js" />                 */}
                {/* <ScriptTag type="text/javascript" src="../../assets/vendor/testimonial/testimonial.js" /> */}
            </React.Fragment >
        )
    }

}
export default About;