import React, { Component } from "react";
import CmsContent from "../../MiddleWare/CmsContent";
import http from "../../MiddleWare/httpMiddleWare";
import Modal from "../modal";
//import Test from "../Header";
import GallerPop from "./galleryPop";
import { ACCESS_POINT } from "../../config";
import ScriptTag from "react-script-tag";
// import PreLoader from "../preloader.js";
import SingleSelect from "../../components/Form/SingleSelect";
import ThemeOne from "./ThemePage/themeOne1";
import ThemeFive from "./ThemePage/themeFive5";
import ThemeSix from "./ThemePage/themeSix6";
import ThemeFour from "./ThemePage/themeFour4";
import ThemeSeven from "./ThemePage/themeSeven7";
import ThemeThree from "./ThemePage/themeThree3";
import ThemeNine from "./ThemePage/themeNine9";
import ThemeEight from "./ThemePage/themeEight8";
import ListingMore from "./listingMore";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: true,
      Adminlog: localStorage.getItem("Admin"),
      userlog: JSON.parse(localStorage.getItem("userDetails")),
      modal14: false,
      alertVisible: false,
      modal145: false,
      AllForm: [],
      CustomerId: 807,
      loginstate: 1,
      themeId: 0,
      InnerData: [],
      error: [],
      answerJson: [],
      loadcomplete: false,
    };
  }

  async componentDidMount() {
    this.setState({ loadcomplete: true })
    // console.log("DID Mount");
    let themeid = this.props.match.params.id;
    // console.log(this.state.Adminlog,"this.state.Adminlog");
    if (this.state.Adminlog == null) {
      const User = await CmsContent.GetAdminDetails(807);
      // console.log(User,"user");
      // console.log(User);
      if (User) {
        localStorage.setItem("Admin", JSON.stringify(User.data[0]));
        this.state.Adminlog = JSON.stringify(User.data[0]);
      }
    }
    this.process(themeid);
  }

  async componentWillReceiveProps(nextProps) {
    let themeid = nextProps.match.params.id;
    // this.setState({pageList:[]});
    if (this.state.Adminlog == null) {
      const User = await CmsContent.GetAdminDetails(807);
      // console.log(User,"user");
      // console.log(User);
      if (User) {
        localStorage.setItem("Admin", JSON.stringify(User.data[0]));
        this.state.Adminlog = JSON.stringify(User.data[0]);
      }
    }
    this.process(themeid);
  }

  process = async (themeid) => {
    let admin = JSON.parse(this.state.Adminlog);
    this.setState({ rightpanel: themeid });
    try {
      // console.log("resuls enter 1");

      const result = await CmsContent.samplesitepage(admin.id, themeid);
      // console.log("resuls",result);
      this.setState({ customerid: themeid });
      if (result && result.data.length) {
        // console.log(result);
        let LS = admin.LoginType;
        if (
          (result.data[0].requireLogin == "1" ||
            result.data[0].requireLogin == "yes") && (this.state.userlog == null)
        ) {
          // console.log(this.state.userlog);
          // console.log(this.state.modal14);

          if (this.state.userlog == null) {
            await this.setState({ modal14: true, requireLogin: true });
          } else {
            this.setState({ modal14: false, requireLogin: false });
          }
          await this.setState({ CustomerId: admin.id, loginstate: LS, pageList: result.data[0] });
        } else {
          let data = result.data[0];
          // console.log("data", data);
          if (data.themeId == "1") {
            this.setState({ pageList: data, themeId: data.themeId });
          } else if (data.themeId == "5") {
            this.setState({ pageList: data });
            data.sheetContent = JSON.parse(data.sheetContent);
            this.setState({
              InnerData: data.sheetContent,
              themeId: data.themeId,
            });
          } else if (data.themeId == "6") {
            this.setState({ pageList: data });
            data.sheetContent = JSON.parse(data.sheetContent);
            //console.log(data.sheetContent);
            let array = data.sheetContent;
            let array1 = [];
            array.map((ival, i) => {
              let kkk = {};
              kkk.name = ival.name;
              kkk.link = ival.link;
              kkk.icon = ival.icon;
              kkk.files = ival.files;
              kkk.tray = ival.tray;
              kkk.drop = false;
              array1.push(kkk);
            });
            //console.log(array1);
            this.setState({ InnerData: array1, themeId: data.themeId });
          } else if (data.themeId == "7") {
            // console.log(data.sheetContent)
            data.sheetContent = data.sheetContent
              .split("Coromandel international/")
              .join(`${ACCESS_POINT}/superAdmin/sharefile?filename=Coromandel international/`);
            // .join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);

            this.setState({ pageList: data, themeId: data.themeId });
          } else if (data.themeId == "4") {
            data.sheetContent = JSON.parse(data.sheetContent);
            this.setState({ pageList: data, themeId: data.themeId });
          } else if (data.themeId == "3") {
            this.getContent(data);
          } else if (data.themeId == "9") {
            data.sheetContent = JSON.parse(data.sheetContent);
            this.setState({
              pageList: data,
              InnerData: data.sheetContent,
              themeId: data.themeId,
            });
          } else if (data.themeId == "8") {
            // console.log(data);
            let FormId = data.sheetContent;
            let customerId = data.customerId;
            let contentTitle1 = data.name;
            let pageId = parseInt(data.id);
            let Cap = data.subTitleId;
            this.setState({ FormId, pageList: data, themeId: data.themeId });
            var captcha = await CmsContent.createcaptcha();
            // console.log(data);
            this.setState({
              pageList: data,
              dataimage: captcha.data.data,
              captchacapturevalid: captcha.data.text,
              Cap: true,
              pageId
            });
            this.getForm(this.state.FormId);
          }
        }
      }
      this.setState({ loadcomplete: false })
    } catch (error) {
      console.log(error);
    }
  };

  getForm = async (formid) => {
    let result = await CmsContent.getTwoConditionedValue(
      "tbl_formquestion",
      "parentid",
      formid,
      1,
      1
    );
    let answerJson = [];

    result.data.map((ival, i) => {
      answerJson[ival.id] = {
        answer: "-",
        formid: ival.parentid,
        questionId: ival.id,
      };
    });
    //console.log(result.data);

    await this.setState({ AllForm: result.data, answerJson });
    await this.process1();
  };
  process1 = async () => {
    let data = await this.state.AllForm;
    if (data) {
      let arr = data;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let answerJsons = answerJson.length;
        let json = await arr.map(async (item, index) => {
          if (item.type == 'text') {
            await contentJson.push(
              <div style={{ marginBottom: '10px' }}>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e => this.answers(e.target.value, index, null, null, 'text', item.id, item.parentid, item.question)}
                    />
                    <span className="error-shows" style={{ color: 'red' }} id={`${index}`}>{this.state.error[index]}</span>
                  </div>
                  <div className="col-sm-3">
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == 'textarea') {
            await contentJson.push(
              <div style={{ marginBottom: '10px' }}>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea type="text" className="form-control" placeholder={item.placeholder} onChange={e => this.answers(e.target.value, index, null, null, 'textarea', item.id, item.parentid, item.question)}></textarea>
                    <span className="error-shows" style={{ color: 'red' }} id={`${index}`}>{this.state.error[index]}</span>
                  </div>
                  <div className="col-sm-3">

                  </div>
                </div>
              </div>
            )
          }
          if (item.type == 'radio') {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div style={{ marginBottom: '10px' }}>
                  <div className="form-check form-check-inline">
                    <input type="radio" name="element" value={`${element}`} id={`inlineCheckbox${key}`} onChange={e => this.answers(element, key, null, key, 'radio', item.id, item.parentid, item.question)} />
                    <label className="form-check-label" for={`inlineCheckbox${key}`} style={{ marginLeft: "10px" }}>{`${element}`} </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div style={{ marginBottom: '10px' }}>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                  </div>
                  <div className="col-sm-3">
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == 'selectbox') {
            let option = [];
            item.options.split(",").map((ival, i) => {
              option.push({ label: ival, value: i });
            })
            await contentJson.push(
              <div style={{ marginBottom: '10px' }}>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5" >
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => { this.answers(e, index, null, null, 'selectbox', item.id, item.parentid, item.question) }}
                      selectedService={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                  <div className="col-sm-3">
                  </div>
                </div>
              </div>
            )
          }
          if (item.type == 'checkbox') {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}${key}`} onChange={e => { this.answers(element, index, item.options.split(","), key, 'checkbox', item.id, item.parentid, item.question) }} value={element} />
                  <label className="form-check-label" for={`inlineCheckbox${index}${key}`} style={{ marginLeft: "10px" }}>{`${element}`}</label>
                </div>
              );
            });
            await contentJson.push(
              <div style={{ marginBottom: '10px' }}>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                  </div>
                </div>
              </div>
            );
          }

        });
        await Promise.all(json);
        //console.log(contentJson);
        await this.setState({ json: contentJson, answerJson });

      }
    }
  }
  getCaptcha = async e => {
    this.setState({ [e.target.name]: e.target.value });
  }
  formSubmit = async () => {
    let error = [...this.state.error];
    let e = [];
    let user = []


    // console.log(this.state.AllForm)


    this.state.AllForm.map((item, i) => {

      this.state.answerJson.map((element, key) => {

        if (item.required == 1 && item.id == element.questionId && (element.answer == null || element.answer == "-")) {
          error[i] = "Value must not be empty";
          e.push(error[i]);
        } else if (item.required == 1 && item.id == element.questionId && (element.answer != null || element.answer != "-")) {
          error[i] = null
          e.push(error[i]);
          // user.push(element)
        }


      })
      if (item.id == 708) {
        this.state.answerJson.map((iv, m) => {
          if (iv.questionId == 708) {
            // console.log(jv);
            if (iv.answer == "-") {
              error[i] = `Email must not be empty`;
              e.push(error[i]);
            }
            else if (
              !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                iv.answer
              )
            ) {
              error[i] = "Enter Valid Email";
              e.push(error[i]);
            } else if (item.required == 1 && item.id == iv.questionId && (iv.answer != null || iv.answer != "-")) {
              error[i] = null
              e.push(error[i]);
              // user.push(iv)
            }
          }

        });
      }
    });

    this.setState({ error: error });
    this.process1();
    for (var i = 0; i < e.length; i++) {
      if (e[i] != null) {
        return false;
      }
    }
    let answer = [];
    this.state.answerJson.map((item, i) => {
      let categoryArray = {};
      categoryArray.formid = item.formid;
      categoryArray.questionId = item.questionId;
      categoryArray.answer = item.answer;
      categoryArray.userid = this.state.userlog ? this.state.userlog.id : null;
      categoryArray.companyid = this.state.CustomerId;
      categoryArray.portid = this.state.pageId;
      answer.push(categoryArray)
      if (item.answer) {
        user.push(item)
      }
    });
    if (this.state.Cap === true) {
      if (!this.state.captchacapture) {
        this.setState({ errorcaptchacapture: 'Please enter captcha' })
        return true
      } else {
        if (this.state.captchacapturevalid != this.state.captchacapture) {
          this.setState({ errorcaptchacapture: 'Please enter correct captcha' })
          return true
        } else {
          this.setState({ errorcaptchacapture: '' })
        }
      }
    }
    try {
      let result = await CmsContent.chanAnswer('tbl_formanswer', answer, this.state.pageId, user);
      if (result) {
        //console.log(result);
        // this.getAttendedForms();
        await this.setState({
          arr: [], alertVisible: true, textalert: "Your Form Have Been Submitted",
          color: "success",
          answerJson: []
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
          this.setState({ alertVisible: false })
          window.location.href = '/'
        }, 3000);
        //window.location.href="/corp/commonpg/247";
        // this.listform();
      }
    } catch (error) {
      console.log(error);
    }

  }
  async answers(label, index, options = null, key = null, type, questionId, formid, question) {

    let answerJson = await this.state.answerJson;
    // console.log(type, label, questionId);
    //let answerformail =await this.state.mailans
    if (type == "text") {

      answerJson[questionId] = { answer: label, questionId: questionId, formid: formid, question: question };
    } else if (type == 'textarea') {
      answerJson[questionId] = { answer: label, questionId: questionId, formid: formid, question: question };
    } else if (type == 'selectbox') {
      answerJson[questionId] = { answer: label.label, value: label.value, questionId: questionId, formid: formid, question: question };
    } else if (type == 'radio') {
      answerJson[questionId] = { answer: label, value: key, questionId: questionId, formid: formid, question: question };
    } else if (type == 'checkbox') {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
        if (checkBox.checked == true) {
          check.push(ival)
        }
      });
      answerJson[questionId] = { answer: check.toString(), value: key, questionId: questionId, formid: formid, question: question };
    }
    await this.setState({ answerJson });
  }
  getContent = async (data) => {
    let userlog1 = localStorage.getItem("userDetails");
    userlog1 = JSON.parse(userlog1);
    if (userlog1 === null) {
      userlog1 = localStorage.getItem("Admin");
      userlog1 = JSON.parse(userlog1);
    }
    localStorage.setItem("userId", userlog1.id);
    this.state.userId = userlog1.id;
    if (userlog1.userType == "6") {
      this.state.customerId = userlog1.customerId;
    } else if (userlog1.userType == "3") {
      this.state.customerId = userlog1.id;
    }
    localStorage.setItem("userName", userlog1.userName);
    localStorage.setItem("userEmail", userlog1.email);
    localStorage.setItem("userMobile", userlog1.mobileNumber);
    let ipaddressget = await http.get("https://ipapi.co/json/", function (
      data
    ) {
      console.log(JSON.stringify(data, null, 2));
    });
    //console.log(ipaddressget);
    const date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var newdate = year + "-" + month + "-" + day;
    var timesDate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds +
      "";
    const userlog = new FormData();
    userlog.append("userid", this.state.userId);
    userlog.append("ipaddress", ipaddressget.data.ip);
    userlog.append("datetime", timesDate);
    userlog.append("date", newdate);
    userlog.append("pageid", data.id);
    userlog.append("customerId", this.state.customerId);
    //console.log([...userlog])
    const formData = new FormData();
    formData.append("userId", this.state.userId);
    formData.append("pageid", data.id);
    let countResult = await CmsContent.pageViewCount(formData);
    this.setState({
      pageList: data,
      themeid: data.id,
      countViews: countResult.data.resultcount,
      themeId: "3",
    });
  };

  MobileSign = async (s, v) => {
    this.setState({ [s]: v });
    if (s == "modal145") {
      ////
      window.location.href = `/`
    } else {
      if (this.state.userlog == null) {
        window.location.href = `/`;
      } else {

        if (this.state.pagelistofCourse && this.state.pagelistofCourse.themeName == "VideoContent") {
          window.location.href = `/vidyaonline/VideoContent/${this.state.pagelistofCourse.pageId}`
        }
        else {
          if (this.state.pagelistofCourse && this.state.pagelistofCourse.pageId) {
            window.location.href = `/vidhya/${this.state.pagelistofCourse.categoryName}/${this.state.pagelistofCourse.pageId}`
          } else {

            window.location.href = `/vidhya/mainPage/${this.state.pageList.id}`;
          }
        }
      }
    }
  };

  // gallerypop = async (d, k, j) => {
  //   console.log(k);
  //   console.log(d);
  //   let arr = [];
  //   this.state.InnerData.map((ival, i) => {
  //     if (i === k) {
  //       arr.push(ival);
  //     }
  //   });
  //   console.log(arr);
  //   this.setState({ Alldata: arr, data: d, modal145: true });
  // };
  gallerypop = async (d, k, j) => {
    let arr = [];
    console.log(d);
    if (d) {
      arr.push(d);
    }
    this.setState({ Alldata: arr, data: arr[0], modal145: true });
  };

  Dropopen = async (i, c) => {
    let { InnerData } = this.state;
    if (c == false) {
      InnerData[i].drop = true;
    } else if (c == true) {
      InnerData[i].drop = false;
    }
    this.setState({ InnerData });
  };
  requireLogin = (id) => {
    this.setState({
      requireLogin: true,
      modal14: true,
      pagelistofCourse: id
    })
  }
  render() {
    // console.log(this.state.captchacapturevalid, 'this.state.captchacapturevalid')
    const { themeId, pageList, InnerData, CustomerId, loadcomplete, userlog } = this.state;
    // console.log({ pageList });
    // console.log(this.state.answerJson)
    if (!pageList && loadcomplete) {
      return <div id="preloader"></div>
    } else if (this.state.requireLogin == true) {
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center' }}>
            You Need to Login to View this Page!
          </div>
          {this.state.modal14 === true && (
            <Modal
              modal14={this.state.modal14}
              these={this.MobileSign}
              customerid={this.state.CustomerId}
              loginstate={this.state.loginstate}
            />
          )}
          {/* <div id="preloader"></div> */}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment >
          {this.state.modal145 === true && (
            <GallerPop
              modal145={this.state.modal145}
              these={this.MosbileSign}
              data={this.state.data}
              Alldata={this.state.Alldata}
            />
          )}
          {themeId == "1" ? <ThemeOne data={pageList} /> : null}
          {themeId == "3" ? (
            <ThemeThree
              data={pageList}
              countViews={this.state.countViews}
              themeid={this.state.themeid}
            />
          ) : null}
          {themeId == "4" ? (
            <ThemeFour pageList={pageList} InnerData={InnerData} />
          ) : null}
          {themeId == "5" ? (
            <ThemeFive data={pageList} InnerData={InnerData} />
          ) : null}
          {themeId == "6" ? (
            <ThemeSix
              data={pageList}
              Dropopen={this.Dropopen}
              InnerData={InnerData}
            />
          ) : null}
          {themeId == "7" ? (
            <ThemeSeven
              data={pageList}
              userlog={userlog}
              gallerypop={this.gallerypop}
              InnerData={InnerData}
            />
          ) : null}
          {themeId == "9" ? (
            <ThemeNine
              data={pageList}
              gallerypop={this.gallerypop}
              InnerData={InnerData}
            />
          ) : null}
          {themeId == "8" && this.state.json ? (
            <ThemeEight
              alert={this.state.alertVisible}
              textalert={this.state.textalert}
              data={pageList}
              dataimage={this.state.dataimage}
              json={this.state.json}
              getCaptcha={this.getCaptcha}
              captchacapture={this.state.captchacapture}
              errorcaptchacapture={this.state.errorcaptchacapture}
              formSubmit={this.formSubmit}
              onDismiss={() => this.setState({ alertVisible: false })}
              answers={this.answers} />
          ) : null}
          {/* Vendor JS Files */}
          {pageList.id == 633 &&
            <ListingMore requireLogin={this.requireLogin} />
          }
          <ScriptTag type="text/javascript" src="assets/vendor/swiper/swiper-bundle.min.css" />
          <ScriptTag type="text/javascript" src="assets/js/main.js" />

        </React.Fragment>
      );
    }
  }
}

export default MainPage;
