const env = {
  // ACCESS_POINT: "http://localhost:5000",
  ACCESS_POINT: "https://df2exp.difuza.com",
  // ACCESS_POINT: "https://exp.difuza.com",
  PYTHON_ACCESS_POINT: "http://3.228.46.250:5050",
  // REDIS_ACCESS_POINT: "http://35.169.94.138:6379",
  // REDIS_ACCESS_POINT: "https://df2exp.difuza.com",
  FILEPATH: "https://df2exp.difuza.com/files/"
  // FILEPATH:"http://localhost:5000/files/",
};

module.exports = env;
