//import React from 'react';
import React, { useState, useEffect } from "react";
// import Collapse from "@kunukn/react-collapse";
import renderHTML from "react-render-html";
import { ACCESS_POINT } from '../../../config';




const ThemeSix = ({ data, Dropopen, InnerData }) => {
	console.log('theme 6')
	//const [Dropopen] = useState(0);
	const bar = (i, c) => {
		//this.props.these(i,c);
		//console.log(props)
		//props.these(i,c);
	}
	//console.log(props)
	let content = [];
	InnerData.map((ival, i) => {
		let index = i + 1;
		if (ival.files == 0) {
			content.push(
				<div>
					<div className="row form-group">
						<div className="col-sm-1" />
						<div className="col-sm-2-1">
							<p style={{ color: 'slategray', fontSize: '17px' }}>{index}</p>
						</div>
						<div className="col-sm-8">
							{ival.files == 0 && (
								<a href={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.link}`} target="_blank"><p style={{ color: 'slategray', fontSize: '17px' }}>{ival.name}</p></a>
							)}
						</div>
						<div className="col-sm-1" />
					</div>
				</div>
			)
		} else {
			let caret = [];
			let changeTB = "fa fa-caret-down";
			if (ival.drop == true) {
				changeTB = "fa fa-caret-up";
				caret = [<i class={changeTB} style={{ fontSize: '18px', color: 'red' }}></i>]
			} else if (ival.drop == false) {
				changeTB = changeTB;
				caret = [<i class={changeTB} style={{ fontSize: '18px', color: 'blue' }}></i>]
			}
			content.push(
				<div>
					<div className="row form-group">
						<div className="col-sm-1" />
						<div className="col-sm-2-1">
							<p style={{ color: 'slategray', fontSize: '17px' }}>{index}</p>
						</div>
						<div className="col-sm-8">
							<span style={{ cursor: 'pointer' }} onClick={() => Dropopen(i, ival.drop)}><p>{ival.name}&nbsp;{caret}</p> </span>

							{/* <Collapse 
                     
					isOpen={ival.drop}>
					<div>
				   {ival.files.map((jval,j)=>{
						return (
					 <div>
					<a href={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.link}`} target="_blank"><p style={{color:'slategray',fontSize:'17px'}}>{jval.name}</p></a>
					</div>) 
					})
					} 
					</div> 
					</Collapse> */}
						</div>
						<div className="col-sm-1" />
					</div>
				</div>
			)
		}
	});
	return (
		<React.Fragment>
			<div class="breadcrumb-area">
				<div class="container" >
					<div class="row" >
						{/*Theme5{this.props.data}*/}

						<div class="col-10" style={{ marginTop: '7%' }}>
							<nav aria-label="breadcrumb" className="smallroute">
								<ol class="breadcrumb" style={{ marginLeft: '0%' }}>
									<li class="breadcrumb-item"><a href={"/"}><i class="fa fa-home"></i>&nbsp;Home</a></li>
									<li class="breadcrumb-item active" aria-current="page" style={{ textTransform: 'capitalize' }}>{data.contentTitle1}</li>
								</ol>
							</nav>
						</div>
						<br />
						<br />

						<div class="about-us-area section-padding-0-100" style={{ marginTop: '2%' }}>
							<div class="container">
								<div class="row">
									{/*<div class="col-1"></div>*/}
									<div class="col-12">
										<div class="about-content" style={{ marginTop: "17px", paddingTop: '50px', boxShadow: '0 10px 30px 0 #000000' }}>
											<div class="section-heading text-center">
												<h2 style={{ textTransform: 'capitalize', color: "#191919" }}>
													{data.contentTitle1}
												</h2>
											</div>
											{content}
											<div className="row form-group">
												<div className="col-sm-1" />
												<div className="col-sm-10">
													{data.content1 && (
														<p>{renderHTML(data.content1)}</p>)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default ThemeSix;
