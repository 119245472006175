import React, { Component } from "react";
import ItemsCarousel from "react-items-carousel";
import range from "lodash/range";
import userImage12 from "../../images/CMS/12.jpg";
import userImage13 from "../../images/CMS/13.jpg";
import userImage29 from "../../images/CMS/29.jpg";
import userImage30 from "../../images/CMS/30.jpg";
import userImage31 from "../../images/CMS/31.jpg";
import userImage32 from "../../images/CMS/32.jpg";
import { Link } from "react-router-dom";
import CmsContent from "../../MiddleWare/CmsContent";
import { ACCESS_POINT } from "../../config";
import { Button } from "reactstrap";

class DownPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      content: [],
      adminId: JSON.parse(localStorage.getItem("userDetails")).customerId,
    };
  }

  componentWillMount() {
    this.setState({
      children: [],
      activeItemIndex: 0,
    });
  }
  createChildren = (n) =>
    range(n).map((i) => {
      return (
        <a key={i} href={`/CMS/VideoContent/${this.state.content[i].id}`}>
          <img
            src={this.state.content[i].thumbnail}
            style={{ width: "100%", height: "100%", marginLeft: 12 }}
          />
        </a>
      );
    });

  changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

  async componentDidMount() {
    const result = await CmsContent.getAllVideos("mp4", this.state.adminId);
    if (result) {
      let content = [];
      this.setState({ data: result.data });
      this.state.data.map((value, key) => {
        if (value.file_extension != "mp4") {
          content.push(value);
        }
      });

      this.setState({ content });

      setTimeout(() => {
        this.setState({
          children: this.createChildren(
            content.length,
            this.state.data.thumbnail
          ),
        });
      }, 1000);
    }
  }

  render() {
    const { activeItemIndex, children } = this.state;
    return (
      <React.Fragment>
        <ItemsCarousel
          enablePlaceholder
          numberOfPlaceholderItems={3}
          minimumPlaceholderTime={1000}
          numberOfCards={3}
          gutter={12}
          showSlither={true}
          firstAndLastGutter={true}
          freeScrolling={false}
          requestToChangeActive={this.changeActiveItem}
          activeItemIndex={activeItemIndex}
          activePosition={"center"}
          chevronWidth={24}
          rightChevron={
            <span style={{ fontSize: 70, color: "red" }}>{">"} </span>
          }
          leftChevron={
            <span style={{ fontSize: 70, color: "red" }}>{"<"} </span>
          }
          outsideChevron={false}
        >
          {children}
        </ItemsCarousel>
      </React.Fragment>
    );
  }
}

export default DownPanel;
