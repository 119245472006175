import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import renderHTML from "react-render-html";
// import PreLoader from "../../preloader.js";
import GalleryPop from "../galleryPop";
import { ACCESS_POINT } from "../../../config";

class Theme4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: "",
      date: "",
      //adminId: localStorage.getItem("swtnId"),
    };
  }
  MobileSign = async (s, v) => {
    this.setState({ [s]: v });
  };
  gallerypop = async (d, k, j) => {
    let arr = [];

    if (d) {
      arr.push({ Img: d });
    }
    console.log(arr);
    this.setState({ Alldata: arr, data: arr[0], modal145: true });
  };
  async componentDidMount() {
    let themeid = this.props.match.params.id;
    console.log('Theme 4')
    let cusid = localStorage.getItem("swtnId");
    let r = this.props.match.params.customerid;
    let adminId = "";
    if (r) {
      adminId = r;
    } else if (cusid == r || cusid) {
      //if(cusid==r){
      //console.log(cusid)
      adminId = cusid;
      // }
    }
    //const result = await CmsContent.samplesitepage(adminId, themeid);


    let Homecontent = await CmsContent.samplesitepage(adminId, 522);
    console.log(Homecontent.data[0].sheetContent)
    let vert1 = [];
    let vert2 = [];
    let img1 = [];
    let sideimg = [];
    let firstpara = [];
    let secondpara = []
    let bottomimage_1 = []
    let bottomimage_2 = [];
    let bottomimage_3 = [];
    let bottomimage_4 = [];
    let bottomimage_5 = [];
    let bottomimage_6 = [];


    JSON.parse(Homecontent.data[0].sheetContent).map((content, index) => {

      if (index == 0) {

        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);
        //console.log(content.image)

        img1.push(content.image);

      }
      else if (index == 1) {
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);
        vert1.push(content.image)
        //  console.log(content.image)

      }
      else if (index == 2) {
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);
        vert2.push(content.image)
        // console.log(content.image)
      }
      else if (index == 3) {
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);
        sideimg.push(content.image)

        firstpara.push(<p className="para">{renderHTML(content.text)}</p>)
      }
      else if (index == 4) {


        secondpara.push(<p className="para">{renderHTML(content.text)}</p>)
      }
      else if (index == 5) {
        let src = content.image;
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);
        bottomimage_1.push(
          <img
            className="artimg"
            src={content.image}
            onClick={() =>
              this.gallerypop(
                `${src}`
              )
            }
          />
        )




      }
      else if (index == 6) {
        let src = content.image;
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);

        bottomimage_2.push(
          <img
            className="coverimg"
            src={content.image}
            onClick={() =>
              this.gallerypop(
                `${src}`
              )
            }
          />
        )

      }
      else if (index == 7) {
        let src = content.image;
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);

        bottomimage_3.push(
          <img
            className="imag1"
            src={content.image}
            onClick={() =>
              this.gallerypop(
                `${src}`
              )
            }
          />)



      }
      else if (index == 8) {

        let src = content.image;
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);

        bottomimage_4.push(
          <img
            className="imag2"
            src={content.image}
            onClick={() =>
              this.gallerypop(
                `${src}`
              )
            }
          />

        )


      }
      else if (index == 9) {

        let src = content.image;
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);

        bottomimage_5.push(<img
          className="imag3"
          src={content.image}
          onClick={() =>
            this.gallerypop(
              `${src}`
            )
          }
        />
        )
        //  console.log(content)

      }
      else if (index == 10) {

        let src = content.image;
        content.image = content.image.split("ttsrinath/").join(`${ACCESS_POINT}/superAdmin/file?fileurl=ttsrinath/`);
        //console.log(content)

        bottomimage_6.push(<a
          href={content.image}
          target="_blank"
          rel="noopener"
        >
          <img className="imag4" src={content.image} />
        </a>)


      }


    })


    if (Homecontent) {

      this.setState({ pageList: Homecontent.data });
      console.log(this.state.pageList);
      let homepage = [];
      //homeimage

      homepage.push(
        <div>
          <div classsName="col-sm-12">
            <div className="row">
              <div className="col-12">
                <img className="hmimg" src={img1} alt="" />
              </div>
            </div>
          </div>
          <div className="col-12 hht">
            <div className="row">
              <div className="col-sm-4">
                <div className="col-12">
                  <div className="own">
                    {Homecontent && (<b>Books Published</b>)}

                    <a
                      href="http://www.amazon.in/dp/1947349899"
                      target="_blank"
                      rel="noopener"
                    >
                      <img className="vertimgwid" src={vert1} alt="" />
                    </a>
                    <img className="vertimgwid" src={vert2} alt=''></img>
                  </div>
                </div>
              </div>

              <div className="col-sm-8">
                <div className="row">
                  <div className="col-8">
                    {firstpara}
                  </div>
                  <div classsName="col-2">
                    <img className="sideimg" src={sideimg}></img>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {secondpara}
                  </div>
                </div>
                {/* <div className="col-2">
                  <ul className="homeul">
                    <li>
                      <a className="para" href="/TTS/mainPage/423">
                        More
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-3">
                      {/* <img
                        className="artimg"
                        src={artimg}
                        onClick={() =>
                          this.gallerypop(
                            "SDAS/Video/ttsrinath_246_ttsrinath_246_AE_TTS_Article_1591282923313_1592205205642.jpeg"
                          )
                        }
                      /> */}
                      {bottomimage_1}
                    </div>
                    <div className="col-3">
                      {/* <img
                        className="coverimg"
                        src={coverimage}
                        onClick={() =>
                          this.gallerypop(
                            "SDAS/Video/ttsrinath_246_ttsrinath_246_Cover_AE__1591282950961_1592205258795.jpeg"
                          )
                        }
                      /> */}
                      {bottomimage_2}
                    </div>
                    <div className="col-3">
                      <div className="row">
                        <div className="col-12">
                          {/* <img
                            className="imag1"
                            src={imag1}
                            onClick={() =>
                              this.gallerypop(
                                "SDAS/Video/ttsrinath_246_ttsrinath_246_Ron_Adam_1591282974247_1592205305431.jpg"
                              )
                            }
                          /> */}
                          {bottomimage_3}
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {/* <img
                              className="imag2"
                              src={imag2}
                              onClick={() =>
                                this.gallerypop(
                                  "SDAS/Video/ttsrinath_246_ttsrinath_246_What_we_do_1_1591283284467_1592205367191.jpg"
                                )
                              }
                            /> */}
                            {bottomimage_4}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row">
                        <div className="col-12">
                          {/* <img
                            className="imag3"
                            src={imag3}
                            onClick={() =>
                              this.gallerypop(
                                "SDAS/Video/ttsrinath_246_ttsrinath_246_TTS_Profile_1591282996774_1592205447689.jpg"
                              )
                            }
                          /> */}
                          {bottomimage_5}
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {bottomimage_6}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      this.setState({ homepage });
    }
  }

  async componentWillReceiveProps(nextProps) {
    let themeid = nextProps.match.params.id;
    let cusid = localStorage.getItem("swtnId");
    let r = this.props.match.params.customerid;
    let adminId = "";
    if (r) {
      adminId = r;
    } else if (cusid == r || cusid) {
      //if(cusid==r){
      console.log(cusid);
      adminId = cusid;
      // }
    }
    // const result = await CmsContent.samplesitepage(adminId, themeid);
  }

  render() {
    const { pageList, homepage } = this.state;
    // console.log(pageList);
    if (!pageList) {
      return <div id="preloader"></div>
    } else {
      return (
        <React.Fragment>
          {this.state.modal145 === true && (
            <GalleryPop
              modal145={this.state.modal145}
              these={this.MobileSign}
              data={this.state.data}
              Alldata={this.state.Alldata}
            />
          )}
          <div class="breadcrumb-area bgcolor">
            <div class="container bgcolor">
              <div class="row bgcolor">
                <div class="col-10" style={{ marginTop: "7%" }}></div>
                <div
                  class="about-us-area section-padding-0-100"
                  style={{ width: "100%" }}
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="about-content"
                          style={{
                            marginTop: "73px",
                            paddingTop: "50px",
                            boxshadow: "rgb(153, 153, 153) 0px 14px 30px 0px",
                          }}
                        >
                          <div>{homepage}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
export default Theme4;
