import React from "react";
import renderHTML from "react-render-html";
import { ACCESS_POINT } from "../../../config";

const ThemeNine = ({ data, InnerData, gallerypop }) => {
  console.log('theme 9')
  return (
    <React.Fragment>
      <div class="breadcrumb-area bgcolor">
        <div class="container bgcolor">
          <div class="row bgcolor">
            <div class="col-12" style={{ marginTop: '5%'}}>
              <nav aria-label="breadcrumb" className="smallroute">
                <ol class="breadcrumb" style={{ marginLeft: "0%" }}>
                  <li class="breadcrumb-item">
                    <a href="/">
                      <i class="fa fa-home"></i>&nbsp;Home
                    </a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {data.contentTitle1}
                  </li>
                </ol>
              </nav>
            </div>
            <br />
            <br />

            <div
              class="about-us-area section-padding-0-100"
              style={{ marginTop: "2%", width: "100%" }}
            >
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="about-content"
                      style={{
                        marginTop: "17px",
                        padding: "55px",
                        paddingTop: "50px",
                        boxShadow: "0 10px 30px 0 #000000",
                      }}
                    >
                      <div class="section-heading text-center">
                        <h2 class="post-title" style={{ color: "#191919" }}>
                          {data.contentTitle1}
                        </h2>
                      </div>
                      {InnerData.map((ival, i) => {
                        return (
                          <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-12">
                                <div class="section-heading text-center">
                                  <h4
                                    class="post-title"
                                    style={{ color: "#191919" }}
                                  >
                                    {ival.title}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row form-group"
                              style={{
                                marginLeft: "350px",
                                marginTop: "-50px",
                              }}
                            >
                              {ival.files.map((jval, j) => {
                                return (
                                  <div
                                    style={{
                                      paddingRight: "3px",
                                      paddingLeft: "3px",
                                    }}
                                  >
                                    <img
                                      className="imgradius"
                                      style={{ height: "200px" }}
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.Img}`}
                                      alt="something went wrong"
                                      onClick={() => gallerypop(jval, i, j)}
                                    />
                                    <p
                                      onClick={() => gallerypop(jval, i, j)}
                                      style={{
                                        cursor: "pointer",
                                        marginTop: "-31px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {jval.name.slice(0, 10)}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ThemeNine;
