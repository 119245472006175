import React, { Component } from "react";
import { Link } from "react-router-dom";
// import logo1 from './logo/VidhyaOnline new.png'
import logo1 from './logo/E-Learning.jpg'
// import {
//     MDBContainer,
//     MDBBtn,
//     MDBModal,
//     MDBModalBody,
//     MDBModalHeader,
//     MDBModalFooter,
// } from "mdbreact";


import { ReactComponent as CloseMenu } from "./assets/x.svg";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import "./header.css";
import CmsContent from "../../MiddleWare/CmsContent";
// import Modal from "../modal";
import Modal from "../LoginModal";
class Test extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            click: false,
            categoryArrray: [],
            fullList: [],
            prevScrollPos: 0,
            themeId: null,
            screen: window.screen.height,
            userlog: localStorage.getItem("userDetails"),
            model: [],
            loading: false,
            error: null,
            modal14: false,
            modal15: false,
            mobileNumber: "",
            errormobileNumber: "",
            loginstate: 1,
            logButton: "Login",
            rot: 0,
            Adminlog: localStorage.getItem("Admin"),
            nav: "navbarToggler",
            cm: "classy-menu",
            exemption: [780],
            exceptionData: [],
            nodisplay: "none",
            display: true,
        };
    }


    async componentDidMount() {

        this.categoryfetch();

    }
    CheckActive = (d) => {
        let isactive = false;
        let first = window.location.href.split('/')[4] == d;
        // console.log(first, window.location.href.split('/')[4]);
        return first ? 'ActiveOption' : ''
    }
    async categoryfetch() {
        try {
            let adminId = 807;

            this.setState({ rot: adminId });
            const result = await CmsContent.getHeader(adminId);

            // console.log(result, "header")
            if (result) {
                var categories = result.data;
                var categoryData = [];
                var fullList = [];
                var content = [];
                if (categories.length > 0) {
                    const map = new Map();
                    for (const item of categories) {
                        if (!map.has(item.categoryId)) {
                            map.set(item.categoryId, true); // set any value to Map
                            categoryData.push({ categoryId: item.categoryId });

                        }
                    }


                    // console.log(categoryData)
                    fullList = categoryData.map((values) => {
                        return categories.filter(
                            (list) => list.categoryId === values.categoryId
                        );
                    });
                }
                var show = false;
                var exceptionData = [];
                const { exemption } = this.state;
                let usr = JSON.parse(this.state.userlog);
                //  let userdept = usr.deptid;
                fullList = fullList.filter((ival, i) => {
                    if (exemption.indexOf(ival[0].categoryId) === -1) {
                        // console.log(ival);
                        return ival;
                    } else {
                        // console.log(ival);
                        exceptionData.push(ival);
                    }
                    return null;
                });
                Array.prototype.move = function (from, to) {
                    this.splice(to, 0, this.splice(from, 1)[0]);
                    return this;
                };
                var arr = fullList
                arr.move(5, 2);
                arr.move(5, 4);
                // console.log("fullList", fullList);
                fullList.map((values) => {

                    let path = "";
                    if (values && values[0]) {
                        // console.log(values[0], "values[0]");
                        if ((values[0].subCategoryId !== "-" && values[0].subCategoryId !== null) && values[0].status == "active") {
                            let innerContent = [];
                            values = values.sort(
                                this.compareValues("listingsubcatagory", "int", "asc")
                            );

                            values.map((ival) => {
                                if (ival.requireLogin == "1" || ival.requireLogin == "yes") {
                                    show = true;
                                }
                                if (ival.deptid == "-" && ival.deptid == "null") {
                                    if (ival.subCategoryName && ival.status == "active") {
                                        // console.log("calling subca")
                                        innerContent.push(
                                            <li className="option">
                                                <a href={`/vidhya/${values[0].categoryName}/${values[0].pageId}`} onClick={() => this.call(values[0].pageId)}>
                                                    {ival.subCategoryName}
                                                </a>
                                            </li>
                                        );
                                    } else if ((ival.subCategoryName == "-" && ival.subCategoryName == "null") && ival.status == "active") {
                                        innerContent.push(
                                            <li className="option">
                                                <a href={`/vidhya/${values[0].categoryName}/${values[0].pageId}`} onClick={() => this.call(values[0].pageId)}>
                                                    {ival.subCategoryName}
                                                </a>
                                            </li>
                                        );
                                    }
                                }
                            });

                            // content.push(
                            //     <li class="option">
                            //         <a href="#">{values[0].categoryName}</a>
                            //         <ul
                            //             id={`${values[0].categoryName}`}
                            //             className="newdropdown"
                            //             onClick={() => {


                            //             }}
                            //         //style={{ boxShadow: "12px 27px 20px rgba(0, 0, 0, 0.3)" }}
                            //         >
                            //             {innerContent}
                            //         </ul>
                            //     </li>
                            // );

                        } else {
                            //console.log(values)
                            if (
                                values[0].requireLogin == "1" ||
                                values[0].requireLogin == "yes"
                            ) {
                                show = true;
                            }
                            // console.log(values[0], 'themename');
                            if (values[0].categoryName != "-" && values[0].categoryName != null) {
                                if (values[0].categoryName && values[0].status == "active") {

                                    if (values[0].pageId == 629) {
                                        content.push(
                                            <li class={`option ${this.CheckActive(values[0].categoryName)}`}>
                                                <Link
                                                    to={`/`}
                                                    onClick={() => this.call(values[0])}
                                                >
                                                    {values[0].categoryName}
                                                </Link>
                                            </li>
                                        );
                                    } else if (values[0].themeName == "VideoContent") {
                                        if (this.state.userlog && this.state.userlog.length) {
                                            content.push(
                                                <li class={`option ${this.CheckActive(values[0].categoryName)}`}>
                                                    <Link
                                                        to={`/vidyaonline/VideoContent/${values[0].pageId}`}
                                                        onClick={() => this.call(values[0])}
                                                    >
                                                        {values[0].categoryName}
                                                    </Link>
                                                </li>
                                            );
                                        }

                                    }
                                    else if (values[0].categoryName == "About") {
                                        // if (this.state.userlog && this.state.userlog.length) {
                                        content.push(
                                            <li class={`option ${this.CheckActive('Testimonials')}`}>
                                                <Link
                                                    to={`/vidyaonline/Testimonials/${values[0].pageId}`}
                                                    onClick={() => this.call(values[0])}
                                                >
                                                    {/* {values[0].categoryName} */}
                                                    Testimonials
                                                </Link>
                                            </li>
                                        );
                                        // }
                                    }
                                    else {
                                        content.push(
                                            <li class={`option ${this.CheckActive(values[0].categoryName)}`}>
                                                {/* <a href='#' onClick={() => this.call(values[0])}>
                                {values[0].categoryName}
                            </a> */}
                                                <Link
                                                    to={`/vidhya/${values[0].categoryName}/${values[0].pageId}`}
                                                    onClick={() => this.call(values[0])}
                                                >
                                                    {values[0].categoryName}
                                                </Link>
                                            </li>
                                        );
                                    }
                                } else if ((values[0].categoryName == "-" && values[0].categoryName == "null") && values[0].status == "active") {
                                    content.push(
                                        <li class={`option ${this.CheckActive(values[0].categoryName)}`}>
                                            <a href={`/vidhya/mainPage/${values[0].pageId}`} onClick={() => this.call(values[0].pageId)}>
                                                {values[0].categoryName}
                                            </a>
                                            {/* <Link
                            to={`/vidya/mainPage/${values[0].pageId}`}
                            onClick={this.call}
                        >
                            {values[0].categoryName}
                        </Link> */}
                                        </li>
                                    );
                                }
                            }
                        }
                    }

                });
                if (localStorage.getItem("userDetails")) {
                    content.push(
                        <li class={`option ${this.CheckActive('Report')}`}>
                            <Link
                                to={`/Vidya/Quizlog`}
                                onClick={() => this.call()}
                            >
                                Report
                            </Link>
                        </li>
                    );
                }
                // content.push(<ScriptTag type="text/javascript" src="http://localhost:4000/js/jquery-2.2.4.min.js" />);
                // content.push(<ScriptTag type="text/javascript" src="http://localhost:4000/js/plugins.js" />);
                // content.push(<ScriptTag type="text/javascript" src="http://localhost:4000/js/active.js" />);
                this.setState({ categories, content, show, exceptionData });
            }
            // console.log('ad',this.state.Adminlog)
            let test = JSON.parse(this.state.Adminlog);
            //console.log(this.state.Adminlog);
            if (
                this.state.Adminlog == null ||
                test.id !== adminId ||
                test.LoginType !== this.state.loginstate
            ) {
                // console.log(adminId);
                const User = await CmsContent.GetAdminDetails(807);
                if (User) {
                    localStorage.setItem("Admin", JSON.stringify(User.data[0]));
                    // console.log(User.data);
                    let data = User.data[0];
                    if (data.LoginType == 1) {
                        this.setState({ loginstate: 1 });
                    } else if (data.LoginType == 2) {
                        this.setState({ loginstate: 2 });
                    }
                }
            } else if (this.state.Adminlog.length) {
                //console.log(this.state.Adminlog)
                let arr = [];
                arr.push(JSON.parse(this.state.Adminlog));
                let data = arr[0];
                if (data.LoginType == 1) {
                    this.setState({ loginstate: 1 });
                } else if (data.LoginType == 2) {
                    this.setState({ loginstate: 2 });
                }
            }
            if (this.state.userlog == null) {
                this.setState({ logButton: "Login" });
            } else {
                this.setState({ logButton: "Logout" });
            }
            //console.log(this.state.loginstate);
        } catch (error) {
            console.log(error);
        }
    }

    compareValues(key, type, order = "asc") {
        // console.log(1);
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = type === "int" ? parseInt(a[key]) : a[key].toUpperCase();
            const varB = type === "int" ? parseInt(b[key]) : b[key].toUpperCase();

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            //console.log(comparison);
            return order === "desc" ? comparison * -1 : comparison;
        };
    }

    async componentWillReceiveProps(nextProps) {
        this.setState({ themeId: null });
        setTimeout(() => {
            this.setState({ themeId: this.props.themeId });
        }, 500);
    }

    LoginCheck = (data) => {
        // if (data.themeName === "ThemeEight") {
        //     this.props.history.replace(`/vidya/th8/${data.pageId}`);
        // } else {
        //     this.props.history.replace(`/vidya/mainPage/${data.pageId}`);
        // }
        window.location.href = `/`
    };
    call = async (values) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        // console.log(id);
        // if (values.pageId == 629) {
        //     window.location.href = `/`
        // } else if (values.themeName == "VideoContent") {
        //     if (this.state.userlog && this.state.userlog.length) {
        //         window.location.href = `/vidyaonline/VideoContent/${values.pageId}`
        //     } else {
        //         this.login(values)
        //     }
        // }
        // else {
        //     window.location.href = `/vidya/${values.categoryName}/${values.pageId}`
        // }

        // this.closeMobileMenu()
        //  await this.setState({display:false})
        //  setTimeout(() => {
        //   this.setState({display:true})
        //  }, 4000);
        // $(".classy-menu").removeClass("menu-on");
        // $(".navbarToggler").removeClass("active");
    };

    getAllSearchKey = async () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        const { searchValue } = this.state;
        console.log(searchValue);
        let values = searchValue.trim().split(/[ ,]+/);
        if (values) {
            console.log(this.props);
            await localStorage.setItem("search", values)
            this.props.history.push({
                pathname: "/vidya/search",
                state: { data: values },
            });
        }
    };

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.getAllSearchKey();
        }
    };

    handlechange = (d) => {
        this.setState({ mobileNumber: d.target.value });
    };

    MobileSign = async (s, v) => {
        this.setState({ [s]: v });
        const { userlog, rot, theme } = this.state;
        let pageId = 578;

        if (userlog == null) {
            //window.location.href=`/`;
        } else if (userlog.length) {
            //console.log(pageId);
            if (this.state.routepage) {

                this.call(this.state.routepage)
            } else {
                window.location.href = `/`

            }
            this.setState({ logButton: "Logout" });
            // if (theme === "ThemeSeven") {
            //     window.location.href = `/vidya/th7/${pageId}`;
            // } else {
            //     window.location.href = `/vidya/mainPage/${pageId}`;
            // }

        }
    };

    login = (values) => {
        //console.log(this.state.userlog);
        if (this.state.userlog == null) {
            this.setState({ modal14: true, routepage: values });
        } else if (this.state.userlog.length) {
            localStorage.clear();
            this.setState({ modal14: false, logButton: "Login", userlog: [] });
            window.location.href = `/`;

        }
    };


    logout = async () => {
        localStorage.clear()
        await this.categoryfetch();
        window.location.href = '/';
    }
    register = () => {
        this.setState({ modal15: true })
    }


    myFunction = async () => {
        // find current scroll position
        const currentScrollPos = window.pageYOffset;
        const { prevScrollPos } = this.state;
        // set state based on location info (explained in more detail below)

        let display = ((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 60) || currentScrollPos < 10);
        console.log(display);
        this.setState({ display })
        // set state to new scroll position
        this.setState({ prevScrollPos: currentScrollPos })


    };
    handleLogin = () => {

    }
    closeMobileMenu = () => {
        this.setState({ click: false })
    }
    render() {


        const { categories, content, open, userlog, rot, exceptionData, nodisplay, display, click } = this.state;
        let test = JSON.parse(userlog)
        let Admin = this.state.Adminlog && JSON.parse(this.state.Adminlog)
        return (
            <React.Fragment>


                <div className="header">

                    <div className="logo-nav">
                        <div className="logo-container">
                            <a href="/">
                                {
                                    window.screen.width <= 768 ?
                                        <img className="logo" src={logo1} style={{ height: '65px', width: '150px' }} />
                                        :
                                        <img className="logo" src={logo1} style={{ height: '80px', width: '210px' }} />
                                }                            </a>
                        </div>

                        <ul className={click ? "nav-options1 active" : "nav-options1"} style={{}}>
                            {
                                Admin && Admin.id && Admin.id == 807
                                    ?
                                    content && content.length
                                        ?
                                        content
                                        :
                                        <div></div>
                                    :
                                    <div className="d-flex list">
                                        <li class={`option ${this.CheckActive("Home")}`}>
                                            <Link style={{textDecoration:"none"}}
                                                to={`/`}
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        &nbsp;
                                        &nbsp;
                                        <li class={`option ${this.CheckActive("Home")}`}>
                                            <Link
                                                to={`/vidhya/Courses/633`}
                                            >
                                                Courses
                                            </Link>
                                        </li>
                                    </div>


                            }

                            <div className='usernamehide'>
                                {
                                    test &&
                                    <b> &nbsp; Hi , {test.userName}  &nbsp; </b>

                                }
                            </div>
                            {/* for mobile nav modal  */}
                            {/* {localStorage.getItem("userDetails") &&  */}
                            <li className="option mobile-option" >
                                <a href="#" className="sign-up" onClick={() => {
                                    {
                                        test ? this.logout() :

                                            this.login()
                                    }
                                }}>
                                    {test ? 'LOGOUT' : "LOGIN"}
                                </a>
                            </li>
                            {/* } */}
                        </ul>

                    </div>
                    {/* for desktop nav  */}

                    {/* {localStorage.getItem("userDetails") &&    */}
                    <ul className="signin-up" style={{
                        // paddingBottom: test ? 0 : 60,
                        // paddingBottom: 20, 
                        // paddingLeft: test ? 0 : 100,
                        // display:'block'
                        marginBottom: 0
                    }}>
                        <li>
                            {
                                test &&
                                <span style={{ fontSize: 12 }}> &nbsp; Hi, {test.userName}!  &nbsp; </span>
                            }
                        </li>
                        <li>
                            <a href="#" className={test ? "signup-btn" : 'signup-btn'} style={{ fontSize: 12 }} onClick={() => {
                                {
                                    test ? this.logout() :
                                        this.login()
                                }
                            }}>
                                {test ? 'Logout' : 'Login'}
                            </a>
                        </li>
                        <li>
                        </li>
                    </ul>
                    {/* } */}


                    {/* <div class="dropdown">
                                <a href="#" id="imageDropdown" data-toggle="dropdown">
                                    <img src={imag} width="50" height="50" alt="User" />
                                </a>
                                <ul class="dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                    <li role="presentation" style={{textAlign:'center'}}>
                                        <a role="menuitem" tabindex="-1" href="#" onClick={() => {
                                    test ? this.logout() :
                                           this.login()}}>{test ? 'Logout' : 'Login'}</a>
                                           </li>                                    
                                </ul>
                            </div> */}


                    <a href='/' className='second-logo'>
                        <img
                            // src='https://exp.difuza.com/superAdmin/sharefile?filename=Coromandel international/CoromandelLogo_1627889438995.jpg'
                            // style={{ height: '60px', width: '160px', alignItems: 'flex-end' }} />
                            // src="/static_images/newlogodec22-23_1672028809044.png"
                            src={logo1}
                            style={{ height: '90px', width: '300px', alignItems: 'flex-end' }} />

                    </a>



                    <div className="mobile-menu" onClick={() => this.setState({ click: !click })}>
                        {click ? (
                            <CloseMenu className="menu-icon" />
                        ) : (
                            <MenuIcon className="menu-icon" />
                        )}
                    </div>


                </div>


                {(this.state.modal14 === true) && (
                    <Modal
                        modal14={this.state.modal14}
                        these={this.MobileSign}
                        loginstate={this.state.loginstate}
                        customerid={rot}
                    />
                )}


            </React.Fragment>
        );
    }
}

export default Test;
