import React, { Component } from "react";
import renderHTML from "react-render-html";
import CmsContent from "../../MiddleWare/CmsContent";
import DownPanel from "./DownPanel";
import RightPanel from "./RightPanel";
import "./style.css";
import "./videoStyle.css";
import NewVideoPlayer from "../../components/Extra/NewVideoPlayer";
// import PreLoader from "./preLoader";
import http from "../../MiddleWare/httpMiddleWare";

class VideoContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: "",
      themeid: "",
      date: "",
      maxtime: 0,
    };
    let userlog = localStorage.getItem("userDetails");
    userlog = JSON.parse(userlog);
    localStorage.setItem("userId", userlog.id);
    this.state.userId = userlog.id;
    localStorage.setItem("userName", userlog.userName);
    localStorage.setItem("userEmail", userlog.email);
    localStorage.setItem("userMobile", userlog.mobileNumber);
  }
  async componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    await this.getContent(nextProps.match.params.id);
    this.componentWillUnmount();
  }
  onUnload = e => { // the method that will be used for both add and remove event
    this.MainFun();
    e.preventDefault();
    e.returnValue = '';

  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", this.onUnload);
    const date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var newdate = year + "-" + month + "-" + day;
    var timesDate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds +
      "";
    localStorage.setItem("startTime", timesDate);
    localStorage.setItem("themeId", this.props.match.params.id);
    await this.getContent(this.props.match.params.id);
  }
  async componentWillUnmount() {
    this.MainFun()
  }
  MainFun = async () => {
    window.removeEventListener("beforeunload", this.onUnload);
    console.log('hello');
    if (localStorage.getItem('completed')) {
      localStorage.removeItem('completed')
    } else {
      let ipaddressget = await http.get("https://ipapi.co/json/", function (
        data
      ) {
        console.log(JSON.stringify(data, null, 2));
      });
      const date = new Date();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
      var day = date.getDate();
      // var day = date.getUTCDate();
      // var year = date.getUTCFullYear();
      var year = date.getFullYear();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      var newdate = year + "-" + month + "-" + day;
      var timesDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds +
        "";
      var userId = JSON.parse(localStorage.getItem("userDetails")).id;
      var max = localStorage.getItem("max");
      var total = localStorage.getItem("total");
      var startTime = localStorage.getItem("startTime");
      max = max ? max : 0;
      total = total ? total : 0;
      // var themeid = localStorage.getItem("themeId");
      // themeid = themeid ? themeid : this.props.match.params.id;
      var themeid = this.props.match.params.id;
      console.log(JSON.parse(localStorage.getItem("userDetails")).customerId, "cusromer");
      const userlog = new FormData();
      userlog.append("userid", userId);
      userlog.append("ipaddress", ipaddressget.data.ip);
      userlog.append("datetime", timesDate);
      userlog.append("date", newdate);
      userlog.append("startTime", startTime);
      userlog.append("max_timeline", max);
      userlog.append("pageid", themeid);
      userlog.append(
        "customerId",
        JSON.parse(localStorage.getItem("userDetails")).customerId
      );
      // console.log([...userlog]);
      let datauser = await CmsContent.addMaster("tbl_CMSuserAccessLog", userlog);

      let data = [];
      let Innerdata = {};
      Innerdata.userid = userId;
      Innerdata.maxtime = max;
      Innerdata.totaltime = total;
      Innerdata.pageid = themeid;
      data.push(JSON.stringify(Innerdata));
      const log = new FormData();
      log.append("fullList", data);
      console.log("full", data);
      await CmsContent.insertMaster("videos_view_count", data);

      localStorage.setItem("startTime", timesDate);
      localStorage.setItem("themeId", this.props.match.params.id);
      localStorage.setItem("max", 0);
      // window.location.href = window.location.origin + `/vidhya/Courses/633`
    }
  }
  // componentDidUpdate(){
  //   if (localStorage.getItem('completed')) {
  //     window.removeEventListener("beforeunload", this.onUnload);  

  //   }
  // }
  async getContent(themeid) {
    console.log(this.state.userId);
    console.log(themeid);
    const formData = new FormData();
    formData.append("userId", this.state.userId);
    formData.append("pageid", themeid);
    // let countResult = await CmsContent.pageViewCount(formData);
    // console.log(countResult);
    // let countResult = await CmsContent.getvideocount(
    //   this.state.userId,
    //   themeid
    // );
    let countResult = await CmsContent.getFreedom('*', 'videos_view_count', `userid=${this.state.userId} and pageid=${themeid}`, 1, 'id desc')
    console.log(countResult);
    const result = await CmsContent.getThemebyid(themeid);
    console.log("demo", result);
    // const result = await CmsContent.getVideoContentById(themeid);
    // console.log("result", result);
    if (result) {
      if (result.data && result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0],
        });
      }
      let maxtime = Math.max.apply(Math, countResult.data.map(function (o) { return o.maxtime; }))
      this.setState({
        pageList: result.data[0],
        themeid: themeid,
        //countViews: countResult.data.resultcount,
        countViews: countResult.data.length,
        maxtime,
        playOn: countResult.data[0]
      });
    }
  }
  coverContent = (con) => {
    if (this.state.pageList.linkto && this.state.pageList.linkto != '-') {
      return <a href={"https://" + `${this.state.pageList.linkto}`}>{con}</a>;
    }
    return con;
  };

  render() {
    const { pageList, date } = this.state;

    // if (!pageList) {
    //   return <PreLoader />;
    // } else    


    {
      return (
        <React.Fragment>

          <div className="container-fluid" style={{ paddingTop: 50 }} >
            <div className="row"><div class="col-12" style={{}}>
              <nav aria-label="breadcrumb" className="smallroute">
                <ol class="breadcrumb" style={{ marginLeft: '0%' }}>
                  <li class="breadcrumb-item"><a href={"/"}><i class="fa fa-home"></i>&nbsp;Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{pageList.contentTitle1}</li>
                </ol>
              </nav>
            </div>
              <br />

              <div className="col-sm-12">
                <div className="card ">
                  <div className="card-body ">
                    <div id="pricing" class="pricing">
                      <div class="container-fluid" data-aos="fade-up">
                        <div class="row">
                          <div class="col-lg-18 col-md-24">
                            <div
                            // class="box"
                            >
                              <h3 style={{ textAlign: 'center' }}>
                                {pageList.contentTitle1 &&
                                  this.coverContent(
                                    <h2 className="theme-tiile text-capitalize" style={{ fontWeight: 'bold' }}>
                                      {pageList.contentTitle1}
                                    </h2>
                                  )}
                              </h3>
                              {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                              <ul>
                                {this.state.themeid && (
                                  <NewVideoPlayer Video={this.state.themeid} onUnload={this.onUnload} playOn={this.state.playOn} maxtime={this.state.maxtime} />
                                )}
                              </ul>

                            </div>
                            <div class="btn-wrap">
                              {pageList.content1 &&
                                this.coverContent(
                                  <p className="content-para">
                                    {renderHTML(pageList.content1)}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    {/* <br />
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    {/* <div
                      className="row video-view-eye"
                      style={{ paddingLeft: "0", marginLeft: "-28px", marginTop: '10px' }}
                    >
                      <span className="view-section">
                        <i class="fa fa-eye icon-left" aria-hidden="true">
                          <span className="video-count-spacing">{`${this.state.countViews} views`}</span>
                        </i>
                      </span>
                    </div> */}
                    <br />

                    <div className="blog-post-author d-flex">
                      {pageList.show === "1" && (
                        <div>
                          <div className="author-thumbnail">
                            <img src={pageList.authorimage} alt="" />
                          </div>
                          <div className="author-info">
                            <a className="author-name">
                              {pageList.authorname}, <span>The Author</span>
                            </a>
                            <p>{pageList.authorabout}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-4">
                <div className="card">
                  <div className="card-body">
                    <RightPanel />
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="row">
              <div className="col-sm-12 mb-new">
                <div className="card">
                  <div className="card-body px-0">
                    <DownPanel />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </React.Fragment>
      );
    }
  }
}

export default VideoContent;
