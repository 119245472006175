import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//coromandel

import Home_Temp from "../screens/Coromandel/Home_Temp";
import Footer from "../screens/Coromandel/Footer";
import Header from '../screens/Coromandel/Header'
import VideoContent from "../screens/Coromandel/VideoContent";
export default class TempRoutes extends Component {
  render() {
    return (
      <Router>
        {/*  Admin Routing */}


        <Switch>

          <Route
            exact
            path={"/vidyaonline/VideoContent/:id"}
            render={props => (
              <React.Fragment>
                <Header {...props} />
                <div className="app-body ">
                  <VideoContent {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"*"}
            render={(props) => {
              return (
                <React.Fragment>
                  <div className="app-body ">
                    <Home_Temp {...props} />
                  </div>
                </React.Fragment>
              );
            }}
          />
        </Switch>
        {/* REouting End */}
      </Router>
    );
  }
}
