import React, { Component } from "react";
// import logo from './logo/Coromandel Logo.jpg'
// import logo1 from './logo/VidhyaOnline Multi Lingual 1.png'
import IMG from "../../images/img/hero-bg.jpg";
import CmsContent from "../../MiddleWare/CmsContent"
import ScriptTag from "react-script-tag";
import { ACCESS_POINT } from "../../config";
import renderHTML from "react-render-html";
// import main from './assets/css/style.css'
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Header from './Header'
import Modal from "../modal";
// import ListingMore from "./listingMore";
const videoScript = "/assets/js/video-player.js";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [],
      loginShow: false,
      play: true,
      hide: true,
      NavViewer: [],
      CustomerId: 807,
      loginstate: 1,
      modal14: false,
    }
  }
  componentDidMount = async () => {
    setTimeout(() => {
      this.setState({ hide: false })
    }, 1000);
    //Instruction

    //mounting call back end to get dynamice category list to how on the nav header

    // let result = await CmsContent.getFreedom(
    //   "*",
    //   "tbl_category_master",
    //   `customerId = 807`,
    //   "id",
    //   "id"
    // )
    let adminId = 807;
    //let adminId=this.props.match.params.customerid ? this.props.match.params.customerid : this.state.adminId ;
    // this.setState({ rot: adminId });
    const result = await CmsContent.getHeader(adminId);
    await this.getContent(643);
    if (result) {
      // console.log(result)


      let NavViewer = []
      result.data.map((ival) => {
        NavViewer.push(
          <li>
            <Link
              style={{ fontSize: "13px" }}
              to={`/vidhya/${ival.categoryName}/${ival.pageId}`}
              onClick={this.call}
            >
              {ival.categoryName}
            </Link>
          </li>
        )
      })

      await this.setState({ header: result.data, NavViewer })
    }
  }

  getContent = async (themeid) => {
    this.setState({ loadcomplete: true })
    // localStorage.setItem("themeId", this.props.match.params.id);
    const result = await CmsContent.getThemebyid('643');


    this.setState({
      InnerData: JSON.parse(result.data[0].sheetContent),

    })
  }
  MobileSign = async (s, v) => {
    this.setState({ [s]: v });
    if (s == "modal145") {
      ////
      window.location.href = `/`
    } else {
      if (this.state.userlog == null) {
        window.location.href = `/`;
      } else {
        window.location.href = `/`;
      }
    }
  };
  playvideo = () => {

  }
  requireLogin = () => {
    this.setState({
      requireLogin: true,
      modal14: true
    })
  }
  render() {
    const { header, NavViewer, play, InnerData } = this.state;
    let wid = false;
    if (window.screen.width <= 768) {
      wid = true
    } else {
      wid = false
    }
    if (this.state.hide) {
      return (
        // <div id="preloader"></div>
        <div></div>
      )
    } else if (this.state.requireLogin == true) {
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center' }}>
            You Need to Login to View this Page!
          </div>
          {this.state.modal14 === true && (
            <Modal
              modal14={this.state.modal14}
              these={this.MobileSign}
              customerid={this.state.CustomerId}
              loginstate={this.state.loginstate}
            />
          )}
          {/* <div id="preloader"></div> */}
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <div>

            {/* //Instruction
            //mounting call back end to get dynamice category list to how on the nav header 
            Use styling with caution every change will reflect on all pages from here
            because of using a common classnames
            On clicking the get started button in nav header opens an input field for getting login credentials
            Link must be add to li tag inside the header state variable
            home page section can be implement her or we can also seperate as different components for future
        */}

            {/* hero section */}

            <section id='home-bg' className="" data-aos="fade-right" data-aos-delay="100">
              <div className="" >
                {/* <div  > */}
                {/* <img src="1463851.jpg"
                  // style={{ backgroundRepeat: "no-repeat", backgroundSize: "", backgroundPosition: "center", width: "100%", height: "auto", visibility: "inherit" }}
                  /> */}
                {/* </div> */}
                {/* <div id="banner" style={{ textShadow: '0 5 black' }} data-aos="fade-left" data-aos-delay="100" className="text-center">
                  <h1 style={{ padding: "25px", color: "black" }}>VIDHYAONLINE <br />FREEDOM OF LEARNING</h1>
                  <h3>ANY TIME ANY WHERE</h3>
                </div> */}
              </div>
              {/* <div
                class="container position-relative"
                data-aos="zoom-in" data-aos-delay="100"> */}
              {/* <video src="assets/img/demo.mp4" /> */}

              {/* <video
                src="assets/img/demo.mp4"
                id="video"
                controls
                style={{ width: "100%", marginTop: "15%", display: play ? 'none' : '', backgroundSize: 'cover' }}
                crossOrigin="anonymous"
                preload="metadata"
                autoplay
              // muted
              >
                <source src="assets/img/demo.mp4" type="video/mp4"></source>
              </video> */}

              {
                window.screen.width <= 768
                  ?
                  <>
                    <div classsName="row">
                      {/* <div className="col-sm-3"></div> */}
                      <div className="col-sm-12" style={{ padding: "23px", textAlign: 'center', textShadow: ' black', fontWeight: 600, height: 60, fontSize: "large" }}> E-LEARNING </div>
                      {/* <div className="col-sm-3"></div> */}
                    </div>
                    <div classsName="row">
                      <div className="col-sm-12" style={{ textAlign: 'center', textShadow: ' black', fontWeight: 300, }}> <h6 >
                        Freedom of Learning - Any Time Any Where</h6> </div>
                    </div>
                    {/* <div id="banner" style={{ top: play ? '20%' : '40%', textShadow: '0 5 black', left: wid ? "0%" : "18%" }} data-aos="zoom-in" data-aos-delay="100" >
                      <h4 style={{ padding: "23px" }}>VIDHYAONLINE
                        <br />FREEDOM OF LEARNING
                      </h4>
                      <h6 style={{ fontFamily: "" }}>Freedom of Learning - Any Time Any Where</h6>

                      <div id='video-controls'>

                        <button
                      id="playpause"
                      class="btn-get-started"
                      type="button"
                      data-state="play"
                      onClick={() => {
                        this.setState({ play: !play })
                        var videoControls = document.getElementById('hero');
  
                        if (play) {
                          videoControls.style.background = 'none'
                        } else {
                          videoControls.style.background = 'url("assets/img/coro.jpeg") top center';
                          videoControls.style.backgroundSize = 'cover'
                        }
                      }}
                     >
                      {play ? 'Play' : 'Pause'}
                    </button>
                      </div>
                    </div> */}
                  </>

                  : window.screen.width > 768 && window.screen.width < 1350
                    ?
                    <div id="banner" style={{ top: play ? '20%' : '40%', textShadow: '0 5 black', left: wid ? "0%" : "18%" }} data-aos="zoom-in" data-aos-delay="100" >
                      <h1 style={{ padding: "23px", marginLeft: "10px" }}> E-LEARNING
                        {/* <br />FREEDOM OF LEARNING */}
                      </h1>
                      <h4 style={{ fontFamily: "" }}>Freedom of Learning - Any Time Any Where</h4>

                      <div id='video-controls'>

                        {/* <button
                    id="playpause"
                    class="btn-get-started"
                    type="button"
                    data-state="play"
                    onClick={() => {
                      this.setState({ play: !play })
                      var videoControls = document.getElementById('hero');

                      if (play) {
                        videoControls.style.background = 'none'
                      } else {
                        videoControls.style.background = 'url("assets/img/coro.jpeg") top center';
                        videoControls.style.backgroundSize = 'cover'
                      }
                    }}
                   >
                    {play ? 'Play' : 'Pause'}
                  </button> */}
                      </div>
                    </div>
                    :
                    <div id="banner" style={{ top: play ? '20%' : '40%', textShadow: '0 5 black', left: wid ? "0%" : "18%" }} data-aos="zoom-in" data-aos-delay="100" >
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <h1 style={{ padding: "23px", fontSize: "50px", fontWeight: 600 }}>E-LEARNING</h1>
                          <div classsName="row">
                            <div className="col-sm-12" style={{ textAlign: 'center', textShadow: ' black', fontWeight: 300 }}> <h6 style={{ fontSize: "25px" }} >
                              Freedom of Learning - Any Time Any Where</h6> </div>
                          </div>
                        </div>
                      </div>
                    </div>

              }

              {/* </div> */}
            </section>


            {/* </div> */}

            <main id='main' >

              {/* About Section */}
              <section id="about" class="about">

                <div class="container" data-aos="fade-up">

                  <div class="row" style={{ textAlign: 'justify', alignItems: 'center' }}>
                    <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                      <img src="https://images.pexels.com/photos/5553066/pexels-photo-5553066.jpeg?auto=compress&cs=tinysrgb&w=600" class="img-fluid" alt="sorry" />
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                      {
                        window.screen.width <= 768
                          ?
                          <h5 style={{ fontWeight: 600, textAlign: "initial", wordSpacing: "4px" }}>Coromandel  knowledge  transfer  initiative.</h5>
                          :
                          <h3>Difuza knowledge transfer initiative.</h3>
                      }

                      &nbsp;
                      <p class="fst-italic">
                        <b style={{ fontSize: "20px" }}> Difuza </b>has always been committed to Agriculture and has served the farming community through its superior products and services. We also know that these magnificent products can be put to best use only though right knowledge about them.
                        .
                      </p>
                      <p class="fst-italic">
                        Since many years, we have leveraged technology enabled learning to train our teams who serve our farmers, dealers and other stakeholders. Through e-learning (Online knowledge through internet) -  E-LEARNING  and with the widespread usage of smart devices, be it phones, tablets, laptops and other gadgets, one can learn on the go, at their own pace.
                        .
                      </p>
                      <p class="fst-italic">
                        <b style={{ fontSize: "20px" }}> E-LEARNING </b> is a e-Learning program which is a continuous learning methodology with modules related to agriculture. The topics covered will be on basics of nutrition, basics of Soil, Agronomy practices on various focus crops, product schedule usage information on various focus crops, information on drip irrigation and fertigation & other relevant topics related to agriculture for providing better solutions to the farmers                        .
                      </p>
                    </div>
                  </div>

                </div>
                <br />

                <div class="container" data-aos="fade-up">

                  <div class="row" style={{ textAlign: 'justify', alignItems: 'center' }}>
                    <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                      <img src="https://img.freepik.com/free-photo/e-learning-education-networking-website-study-concept_53876-167089.jpg?ga=GA1.1.32342509.1722862702&semt=ais_hybrid" class="img-fluid" alt="sorry" />
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                      <h3>About  E-LEARNING
                        .</h3><br />
                      <p class="fst-italic">
                        <b style={{ fontSize: "20px" }}>   Difuza </b>Team has developed a digital learning platform “ E-LEARNING ” for the company employees in the year 2016-17.
                        .
                      </p>

                      <p class="fst-italic">
                        In this initiative, enabling field staffs to enhance their productivity with a shorter cycle time was achieved and they were equipped with better knowledge on the agronomic practices and were able to provide right solutions periodically. The solutions and education provided by our field staffs to the farmers resulted in increase of customer loyalty and build trust.
                        .
                      </p>

                      <p class="fst-italic">
                        Now Difuza Team is providing the same learning platform    <b style={{ fontSize: "20px" }}>  “ E-LEARNING ” </b> to our channel partners, so that they can also enhance their customer loyalty through information dissemination to the farmers. This holds true with our    <b style={{ fontSize: "20px" }}>  Difuza’s Mission </b>statement:
                        .
                      </p>

                      <p class="fst-italic">
                        “To enhance the prosperity of the farmers through quality farm solutions with sustainable value for all stakeholders”.                        .
                      </p>

                    </div>
                  </div>

                </div>
                
              </section>

              {/* <!-- ======= Counts Section ======= --> */}
              {/* <section id="counts" class="counts section-bg">
                <div class="container">

                  <div class="row counters">

                    <div class="col-lg-3 col-6 text-center">
                      <span data-purecounter-start="0" data-purecounter-end="1232" data-purecounter-duration="1" class="purecounter"></span>
                      <p>Students</p>
                    </div>

                    <div class="col-lg-3 col-6 text-center">
                      <span data-purecounter-start="0" data-purecounter-end="64" data-purecounter-duration="1" class="purecounter"></span>
                      <p>Courses</p>
                    </div>

                    <div class="col-lg-3 col-6 text-center">
                      <span data-purecounter-start="0" data-purecounter-end="42" data-purecounter-duration="1" class="purecounter"></span>
                      <p>Events</p>
                    </div>

                    <div class="col-lg-3 col-6 text-center">
                      <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
                      <p>Trainers</p>
                    </div>

                  </div>

                </div>
              </section> */}
              {/* <!-- ======= Why Us Section ======= --> */}
              <section id="why-us" class="why-us">
                <div class="container" data-aos="fade-up">

                  <div class="row">
                    <div class="col-lg-4 d-flex align-items-stretch">
                      <div class="content">
                        <h3>Why Choose  E-LEARNING ?</h3>
                        <p style={{ textAlign: 'justify', alignItems: 'center' }}>
                          E-LEARNING  is a e-Learning program which is a continuous learning methodology with modules related to agriculture. The topics covered will be on basics of nutrition, basics of Soil, Agronomy practices on various focus crops, product schedule usage information on various focus crops, information on drip irrigation and fertigation & other relevant topics related to agriculture for providing better solutions to the farmers .
                        </p>
                        <div class="text-center">
                          <a href="/vidyaonline/Testimonials/643" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                      <div class="icon-boxes d-flex flex-column justify-content-center">
                        <div class="row">
                          {InnerData && InnerData.map((ival, i) => {
                            return (i < 3 && (<div class="col-xl-4 d-flex align-items-stretch">
                              <div class="icon-box mt-4 mt-xl-0">
                                {/* <i class="bx bx-receipt"></i> */}
                                <img src={"https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars.png"} alt="sq-sample1" style={{ height: '68px', width: '68px', borderRadius: 50, marginBottom: '30px' }} />
                                <h4>{ival.title}</h4>
                                <p className='truncate'>{renderHTML(ival.description)}</p>
                              </div>
                            </div>))
                          })}
                          {/* <div class="col-xl-4 d-flex align-items-stretch">
                            <div class="icon-box mt-4 mt-xl-0">
                              <i class="bx bx-cube-alt"></i>
                              <h4>Ullamco laboris ladore pan</h4>
                              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                            </div>
                          </div>
                          <div class="col-xl-4 d-flex align-items-stretch">
                            <div class="icon-box mt-4 mt-xl-0">
                              <i class="bx bx-images"></i>
                              <h4>Labore consequatur</h4>
                              <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
              {/* <!-- ======= Features Section ======= --> */}
              {/* <section id="features" class="features">
                <div class="container" data-aos="fade-up">

                  <div class="row" data-aos="zoom-in" data-aos-delay="100">
                    <div class="col-lg-3 col-md-4">
                      <div class="icon-box">
                        <i class="ri-store-line" style={{ color: "#ffbb2c" }}></i>
                        <h3><a href="">Lorem Ipsum</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
                      <div class="icon-box">
                        <i class="ri-bar-chart-box-line" style={{ color: "#5578ff" }}></i>
                        <h3><a href="">Dolor Sitema</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
                      <div class="icon-box">
                        <i class="ri-calendar-todo-line" style={{ color: "#e80368;" }}></i>
                        <h3><a href="">Sed perspiciatis</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4 mt-lg-0">
                      <div class="icon-box">
                        <i class="ri-paint-brush-line" style={{ color: "#e361ff" }}></i>
                        <h3><a href="">Magni Dolores</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-database-2-line" style={{ color: "#47aeff" }}></i>
                        <h3><a href="">Nemo Enim</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-gradienter-line" style={{ color: "#ffa76e" }}></i>
                        <h3><a href="">Eiusmod Tempor</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-file-list-3-line" style={{ color: "#11dbcf" }}></i>
                        <h3><a href="">Midela Teren</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-price-tag-2-line" style={{ color: "#4233ff" }}></i>
                        <h3><a href="">Pira Neve</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-anchor-line" style={{ color: "#b2904f" }}></i>
                        <h3><a href="">Dirada Pack</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-disc-line" style={{ color: "#b20969" }}></i>
                        <h3><a href="">Moton Ideal</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-base-station-line" style={{ color: "#ff5828" }}></i>
                        <h3><a href="">Verdo Park</a></h3>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4 mt-4">
                      <div class="icon-box">
                        <i class="ri-fingerprint-line" style={{ color: "#29cc61" }}></i>
                        <h3><a href="">Flavor Nivelanda</a></h3>
                      </div>
                    </div>
                  </div>

                </div>
              </section> */}


              {/* <ListingMore requireLogin={this.requireLogin} home={true} /> */}
            </main>

            {/* <!-- ======= Footer ======= --> */}

          </div>
          {/* <ScriptTag type="text/javascript" src={videoScript} /> */}
          <ScriptTag type="text/javascript" src='/assets/vendor/purecounter/purecounter.js' />


        </React.Fragment >
      )
    }
  }
}

export default Home;