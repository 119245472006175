import React from 'react';
import renderHTML from "react-render-html";
import "../theme3/style.css";
import "../theme3/videoStyle.css";
import NewVideoPlayer from "../theme3/NewVideoPlayer";

const ThemeThree = ({ data, themeid, countViews }) => {
	console.log(themeid, 'theme 3');
	console.log(data);

	return (<React.Fragment>

		<div class="breadcrumb-area" style={{}} >
			<div class="container" >
				<div class="row" >
					<div class="col-10" style={{}}>
						<nav aria-label="breadcrumb" className="smallroute">
							<ol class="breadcrumb" style={{ marginLeft: '0%' }}>
								<li class="breadcrumb-item"><a href={"/"}><i class="fa fa-home"></i>&nbsp;Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">{data.contentTitle1}</li>
							</ol>
						</nav>
					</div>
					<br />
					<br />

					<div class="about-us-area section-padding-0-100" style={{ width: '100%', }}>
						<div class="container">
							<div class="row">
								<div class="col-12">
									<div class="about-content" style={{ paddingTop: '50px', boxShadow: '0 10px 30px 0 #000000' }}>
										<div class="section-heading text-center">
											{data.contentTitle1 && (
												<h2 class="post-title" style={{ color: "#191919" }}>
													{data.contentTitle1}
												</h2>)}
										</div>
										<div class="post-share">
											{data.content1 && (
												<p>
													{renderHTML(data.content1)}
												</p>
											)}
										</div>
										<div className="row  card-row-height-video">
											<div className="col-sm-12">
												<div>
													{themeid && (
														<NewVideoPlayer Video={themeid} />
													)}
												</div>
											</div>
										</div>
										<div className="row video-view-eye"
											style={{ paddingLeft: "0", marginLeft: "-28px" }}
										>
											<span className="view-section">
												<i class="fa fa-eye icon-left" aria-hidden="true">
													<span className="video-count-spacing">{`${countViews} views`}</span>
												</i>
											</span>
										</div>


									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	</React.Fragment>)
}

export default ThemeThree;
