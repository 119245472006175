import React, { Component } from "react";
import { Link } from "react-router-dom";

import CmsContent from "../../MiddleWare/CmsContent";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            categoryArrray: [],
            fullList: [],
            themeId: null,
            adminId: 807,
            exemption: [41, 43, 44, 45, 46, 47, 49, 58],
            screen: window.screen.height,
            Currentyear: "",
            userlog: localStorage.getItem("userDetails"),
            show: false
        };
    }

    async componentDidMount() {
        try {
            const result = await CmsContent.getHeader(this.state.adminId);
            if (result) {
                //console.log(result.data);
                let date = new Date().getFullYear();
                //console.log(date);
                this.setState({ categories: result.data, Currentyear: date });
                setTimeout(() => { this.setState({ show: true }) }, 1000)
            }
        } catch (error) {
            console.log(error);
        }
        ///<Link className="nav-link" to={`/CMS/Dynamic/218`} onClick={this.call}>
    }
    async componentWillReceiveProps() {
        this.setState({
            themeId: null,
            userlog: localStorage.getItem("userDetails"),
        });
        setTimeout(() => {
            if (this.props.match) {
                this.setState({ themeId: this.props.match.params.id });
            }
        }, 500);
    }

    call = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    render() {
        let { data } = this.props;
        const { categories, themeId, exemption, Currentyear, userlog, show } = this.state;
        //console.log(userlog);
        if (userlog) { var userdept = userlog.deptid; }

        var categoryData = [];
        var reference = [];
        var fullList = [];
        if (categories.length > 0) {
            const map = new Map();
            for (const item of categories) {
                if (!map.has(item.categoryId)) {
                    if (item.deptid == "-" || parseInt(item.deptid) == parseInt(userdept)) {
                        map.set(item.categoryId, true); // set any value to Map
                        categoryData.push({ categoryId: item.categoryId });
                    }
                }
            }

            fullList = categoryData.map((values) => {
                return categories.filter(
                    (list) => list.categoryId === values.categoryId
                );
            });
        }
        var chunk_size = 5;
        var groups = "";
        var fullData = [];
        var home = [];
        var exceptionData = [];
        if (fullList.length > 0) {
            fullList = fullList.filter((ival, i) => {
                if (exemption.indexOf(ival[0].categoryId) === -1) {
                    //console.log(ival);
                    return ival;
                } else {
                    exceptionData.push(ival);
                }
                return null;
            });
            fullList.map((values) => {
                // console.log(values,'valu');
                if (
                    values[0].subCategoryName == null ||
                    values[0].subCategoryName == "-"
                ) {
                    // console.log(values,'valu');

                    // }
                    if (values[0].pageId == 629) {
                        home.push(
                            <li>
                                <i class="bx bx-chevron-right"></i>
                                <Link
                                    to={`/`}
                                    onClick={() => this.call(values[0])}
                                >
                                    {values[0].categoryName}
                                </Link>
                            </li>
                        );
                    }
                    else if (values[0].categoryName == "About") {
                        home.push(
                            <li>
                                <i class="bx bx-chevron-right"></i>
                                <Link style={{ fontSize: "13px" }}
                                    to={`/vidyaonline/Testimonials/${values[0].pageId}`}
                                    onClick={() => this.call(values[0])}
                                >
                                    {/* {values[0].categoryName} */}
                                    Testimonials
                                </Link>
                            </li>
                        );
                        // }
                    } else {
                        home.push(
                            <li>
                                <i class="bx bx-chevron-right"></i>
                                <Link
                                    style={{ fontSize: "13px" }}
                                    to={`/vidhya/${values[0].categoryName}/${values[0].pageId}`}
                                    onClick={this.call}
                                >
                                    {values[0].categoryName}
                                </Link>
                            </li>

                        );
                    }
                }
            });

        }
        let year = new Date().getFullYear();
        return (
            <div>
                {show && <div>
                    <footer id="footer">

                        <div class="footer-top">
                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6 col-md-12 footer-contact">
                                        <h3> E-LEARNING </h3>
                                        <p>
                                            Consortia 22  <br />
                                            No.70, Aspiran Garden 2nd St, Aspiran Garden Colony, <br />
                                            Kilpauk, Chennai,600010, <br />
                                            Tamil Nadu , India. <br />
                                            {/* <strong>Phone:</strong> +91 40 66997300 / 66997500<br />
                                        <strong>Fax:</strong> +91 40 27844117<br />
                                        <strong>Corporate Identity Number:</strong> L24120TG1961PLC000892<br /> */}

                                        </p>
                                    </div>

                                    <div class="col-lg-6 col-md-12 footer-links">
                                        <h4>Useful Links</h4>
                                        <ul>
                                            {/* {
                                            data.map((ival) => {
                                                return (
                                                    <li><i class="bx bx-chevron-right"></i> <a href="#">{ival.categoryName}</a></li>
                                                )
                                            })
                                        } */}
                                            {home}
                                        </ul>
                                    </div>

                                    {/* <div class="col-lg-3 col-md-6 footer-links">
                                    <h4>Our Services</h4>
                                    <ul>
                                        <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                                        <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                                        <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                                        <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                                        <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
                                    </ul>
                                </div>*/}

                                    {/* <div class="col-lg-4 col-md-6 footer-newsletter">
                                    <h4>Join Our Newsletter</h4>
                                    <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                                    <form action="" method="post">
                                        <input type="email" name="email" /><input type="submit" value="Subscribe" />
                                    </form>
                                </div> */}

                                </div>
                            </div>
                        </div>

                        <div class="container d-md-flex py-4">

                            <div class="me-md-auto text-center text-md-start">
                                <div class="copyright" >
                                    {/* &copy; Copyright <strong><span>Difuza</span></strong>. All Rights Reserved */}
                                    <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                                        Copyright &copy; {year} All rights reserved Powered by{" "}
                                        <a href="http://difuza.com/" target="_blank">
                                            difuza
                                        </a>
                                    </p>
                                </div>
                                {/* <div class="credits">
                                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                            </div> */}
                            </div>
                            <div class="social-links text-center text-md-right pt-3 pt-md-0" style={{}}>
                                <a href="https://twitter.com/coromandel_int" target='_blank' class="twitter"><i class="bx bxl-twitter"></i></a>
                                <a href="https://www.facebook.com/CoromandelInternational" target='_blank' class="facebook"><i class="bx bxl-facebook"></i></a>

                                <a href="https://www.linkedin.com/company/coromandel-international-limited" target='_blank' class="linkedin"><i class="bx bxl-linkedin"></i></a>
                            </div>
                        </div>
                    </footer>
                </div>}
            </div>
        )
    }
}

export default Footer
