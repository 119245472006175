import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
//coromandel
import HomeCoromandel from '../../src/screens/Coromandel/Home'
import Footer from "../screens/Coromandel/Footer";
import Header from '../screens/Coromandel/Header'
import MainPage from '../screens/Coromandel/mainPage'
import Search from '../screens/Coromandel/searchList'
import VideoContent from "../screens/Coromandel/VideoContent";
import ListingMore from '../screens/Coromandel/listingMore';
import VideoList from "../screens/Coromandel/VideoList";
import About from "../screens/Coromandel/About";
import Login from "../screens/Coromandel/Login";
import Quizlog from "../screens/Coromandel/Quizlog"
import Home_Temp from "../screens/Coromandel/Home_Temp";
import Home from "../../src/screens/Coromandel/Home";
import CmsCertificate from "../screens/Coromandel/PDF/Certificate";
export default class AdminRoutes extends Component {
  render() {
    return (
      <Router>
        {/*  Admin Routing */}

       
     
        <Route
          exact
          path={"/"}
          render={(props) => {
            props.match.params.id = 629;
            props.match.params.login = false;
            return (
              <React.Fragment>
                <Header {...props} />
                <div className="app-body ">
                  <Home {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            );
          }}
        />
       
        <Route
          exact
          path={"/Vidya/Login/:code?"}
          render={(props) => (
            <React.Fragment>
              {/* <Header {...props} /> */}
              <div className="app-body ">
                <Home {...props} />
                <Login {...props} />
              </div>
              {/* <Footer {...props} /> */}
            </React.Fragment>
          )}
        />

        <Route
          exact
          path={"/vidhya/:name/:id"}
          render={(props) => (
            <React.Fragment>
              <Header {...props} />
              <div className="app-body ">
                <MainPage {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        />
        {/* <Route
          exact
          path={"/Vidya/Login/:code?"}
          render={(props) => (
            <React.Fragment>
             // <Header {...props} /> 
              <div className="app-body ">
                <Login {...props} />
              </div>
              <div className="app-body ">
                <Home_Temp {...props} />
              </div>
              // <Footer {...props} /> 
            </React.Fragment>
          )}
        /> */}
        <Route
          exact
          path={"/Vidya/Quizlog"}
          render={(props) => (
            <React.Fragment>
              <Header {...props} />
              <div className="app-body ">
                <Quizlog {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        />
        {/* <Route
          exact
          path={"/vidya/mainpage/:id"}
          render={(props) => (
            <React.Fragment>
              <Header {...props} />
              <div className="app-body ">
                <MainPage {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        /> */}
        <Route
          exact
          path={"/vidya/search"}
          render={(props) => (
            <React.Fragment>
              <Header {...props} />
              <div className="app-body ">
                <Search {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={"/vidyaonline/VideoContent/:id"}
          render={props => (
            <React.Fragment>
              <Header {...props} />
              <div className="app-body ">
                <VideoContent {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        />

        <Route
          exact
          path={"/vidyaonline/More/:id"}
          render={props => (
            <React.Fragment>
              <Header {...props} />
              <div className="app-body ">
                <ListingMore {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={"/vidyaonline/videolist/:id"}
          render={props => (
            <React.Fragment>
              <Header {...props} />
              <div className="app-body ">
                <VideoList {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={"/vidyaonline/Testimonials/:id"}
          render={props => (
            <React.Fragment>
              <Header {...props} />
              <About {...props} />
              <Footer {...props} />
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={"/Vidya/Certificate/:quiz/:user/:time/:pre/:post"}
          render={props => (
            <React.Fragment>
              <CmsCertificate {...props} />
            </React.Fragment>
          )}
        />
        {/* REouting End */}
      </Router>
    );
  }
}
